import EventConstants from "../constants/EventConstants";

export default function(
	state = {
		events: []
	},
	action
) {
	switch (action.type) {
		case EventConstants.GET_EVENT:
			return { ...state, events: action.payload };
		case EventConstants.ADD_EVENT:
			return {
				...state,
				events: [...state.events, action.payload]
			};
		case EventConstants.DELETE_EVENT:
			return {
				...state,
				events: [
					...state.events.slice(0, action.payload),
					...state.events.slice(action.payload + 1)
				]
			};
		case EventConstants.UPDATE_EVENT:
			return {
				...state,
				events: [
					...state.events.slice(0, action.payload.index),
					{ ...action.payload.updatedItem },
					...state.events.slice(action.payload.index + 1)
				]
			};
		default:
			return state;
	}
}
