import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../shared/components/Layout";
import { Container } from "../../ui";

const LearnHost = "https://learn.mywishes.co.uk";

const LearnBlogRssDetail = (props) => {
	const [data, setData] = useState(null);
	useEffect(() => {
		if(props.match.params.id) {
			(async() => {
				try {
					const res = await axios.get(`${LearnHost}/wp-json/wp/v2/posts/${props.match.params.id}`);
					setData(res.data);
				} catch (e) {
					console.log(e)
				}
				
			})()
		}
	}, [props.match.params.id])

	return (
		<Layout>
			<Container>
				<div>Hello World</div>
				{data && <div dangerouslySetInnerHTML={{__html: data.content.rendered}}/>}
			</Container>
		</Layout>
	)
}	

export default LearnBlogRssDetail
