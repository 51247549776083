import LoginConstants from "../constants/LoginConstants";

export default function (
	state = {
		authenticated: false,
		loading: false,
	},
	action
) {
	switch (action.type) {
		case LoginConstants.LOGIN_REQUEST:
			return { ...state, loading: true };
		case LoginConstants.LOGIN_SUCCESS:
			return { ...state, authenticated: true, loading: false };
		case LoginConstants.LOGIN_FAILURE:
			return { ...state, loading: false };
		case LoginConstants.UNAUTHENTICATED:
			return { ...state, authenticated: false };
		default:
			return state;
	}
}
