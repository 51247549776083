import noteConstants from "../constants/NoteConstants";

export default function (
	state = {
		notes: [],
		loadings: {
			get: false,
			add: false,
			edit: null,
			delete: null,
		}
	},
	action
) {
	switch (action.type) {
		case noteConstants.GET_NOTE_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					get: true
				}
			};
		case noteConstants.GET_NOTE_SUCCESS:
			return { ...state,
				notes: action.payload,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case noteConstants.GET_NOTE_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case noteConstants.ADD_NOTE_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					add: true
				}
			};
		case noteConstants.ADD_NOTE_SUCCESS:
			return { ...state,
				notes: [...state.notes, action.payload],
				loadings: { ...state.loadings,
					add: false
				}
			};
		case noteConstants.ADD_NOTE_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					add: false
				}
			};
		case noteConstants.EDIT_NOTE_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					edit: action.payload
				}
			};
		case noteConstants.EDIT_NOTE_SUCCESS:
			return {
				...state,
				notes: [
					...state.notes.slice(0, action.payload.index),
					{ ...action.payload.updatedData
					},
					...state.notes.slice(action.payload.index + 1)
				],
				loadings: { ...state.loadings,
					edit: null
				}
			};
		case noteConstants.EDIT_NOTE_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					edit: null
				}
			};
		case noteConstants.DELETE_NOTE_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					delete: action.payload
				}
			};
		case noteConstants.DELETE_NOTE_SUCCESS:
			return {
				...state,
				notes: [
					...state.notes.slice(0, action.payload),
					...state.notes.slice(action.payload + 1)
				],
				loadings: { ...state.loadings,
					delete: null
				}
			};
		case noteConstants.DELETE_NOTE_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					delete: null
				}
			};
		default:
			return state;
	}
}
