import digitalLegacyConstants from "../constants/GoodbyeConstants";

export default function (
	state = {
		GoodbyeMessage: null,
		loadings: {
			get: false,
			add: false,
			update: false,
			delete: false,
		}
	},
	action
) {
	switch (action.type) {
		case digitalLegacyConstants.GET_LEGACY_REQUEST:
			return { ...state,
				loadings: {
					...state.loadings,
					get: true
				}
			};
		case digitalLegacyConstants.GET_LEGACY_SUCCESS:
			return { ...state,
				GoodbyeMessage: action.payload,
				loadings: {
					...state.loadings,
					get: false
				}
			};
		case digitalLegacyConstants.GET_LEGACY_FAILED:
			return { ...state,
				loadings: {
					...state.loadings,
					get: false
				}
			};
		case digitalLegacyConstants.ADD_LEGACY_REQUEST:
			return { ...state,
				loadings: {
					...state.loadings,
					add: true
				}
			};
		case digitalLegacyConstants.ADD_LEGACY_SUCCESS:
			return { ...state,
				GoodbyeMessage: action.payload,
				loadings: {
					...state.loadings,
					add: false
				}
			};
		case digitalLegacyConstants.ADD_LEGACY_FAILED:
			return { ...state,
				loadings: {
					...state.loadings,
					add: false
				}
			};
		case digitalLegacyConstants.DELETE_LEGACY_REQUEST:
			return { ...state,
				loadings: {
					...state.loadings,
					delete: true
				}
			};
		case digitalLegacyConstants.DELETE_LEGACY_SUCCESS:
			return {
				...state,
				GoodbyeMessage: null,
				loadings: {
					...state.loadings,
					delete: false
				}
			};
		case digitalLegacyConstants.ADD_LEGACY_FAILED:
			return { ...state,
				loadings: {
					...state.loadings,
					delete: false
				}
			};
		case digitalLegacyConstants.UPDATE_LEGACY_REQUEST:
			return { ...state,
				loadings: {
					...state.loadings,
					update: false
				}
			};
		case digitalLegacyConstants.UPDATE_LEGACY_SUCCESS:
			return {
				...state,
				GoodbyeMessage: action.payload,
				loadings: {
					...state.loadings,
					update: false
				}
			};
		case digitalLegacyConstants.UPDATE_LEGACY_FAILED:
			return { ...state,
				loadings: {
					...state.loadings,
					update: false
				}
			};
		default:
			return state;
	}
}
