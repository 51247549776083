import React from "react";
import * as Sentry from "@sentry/browser";

import Logo from "../static/assets/img/mywishes-logo-black.png";

import Layout from "../shared/components/Layout";

import {
	StyledLogo,
	BackWrapper,
	Description
} from "../shared/components/Error";
import Loading from "../shared/components/Loading";

const LoadingComponent = ({ isLoading, error }) => {
	// Handle the loading state
	if (isLoading) {
		return <Loading size="large" />;
	}
	// Handle the error state
	else if (error) {
		const REACT_APP_MYWISHES_ENV = process.env.REACT_APP_MYWISHES_ENV;

		const isDev = REACT_APP_MYWISHES_ENV === "development";
		console.error(error);
		if(!isDev){
			Sentry.withScope(scope => {
				scope.setTag("path", window.location.pathname);
				Sentry.captureException(error);
				Sentry.captureMessage("Component is failed to load");
			});
		}
		window.location.reload(true);
		return (
			<Layout fixedHeight="100vh">
				<BackWrapper>
					<StyledLogo src={Logo} />
					<Description>
						Sorry there was a problem loading this page. Please
						<a href="/">
							<b> click here </b>
						</a>
						to go back to MyWishes.
					</Description>
				</BackWrapper>
			</Layout>
		);
	} else {
		return null;
	}
};

export default LoadingComponent;
