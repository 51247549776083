import React, { Fragment } from "react";
import { Modal as AntdModal } from "antd";
import { ModalType } from "./type";
import { StyledModalText } from "./styled";

// NEW VEERSION //
const Modal = props => {
	return (
		<AntdModal
			title={props.title}
			visible={props.visible}
			centered={props.centered ? props.centered : true}
			closable={props.closable ? props.closable : false}
			width={props.width ? props.width : 600}
			footer={props.footer ? props.footer : null}
			maskClosable={props.maskClosable ? props.maskClosable : false}
			zIndex={1000}
			destroyOnClose
		>
			<StyledModalText>{props.children}</StyledModalText>
		</AntdModal>
	);
};

ModalType.propTypes = ModalType;

export default Modal;
