import React, { Component } from "react";
import ReactPixel from "react-facebook-pixel";

const FacebookPixel = props => {
	const options = {
		autoConfig: true, // set pixel's autoConfig
		debug: false // enable logs
	};

	ReactPixel.init("420514588684072", null, options);
	ReactPixel.pageView();
};

export default FacebookPixel;
