import React from "react";
import CollapsibleSide from "./CollapsibleSide";
import {Affix} from "antd";

const ListSide = props => {
	const { isListCollapsed, children } = props;

	// return (
	// 	<Affix offsetTop={10}>
	// 		{children}
	// 	</Affix>
	// )

	return (
		<div
			className="listside-root"
			style={{
				background: "transparent",
				position: "relative",
				paddingRight: "2em"
			}}
		>
			{children}
		</div>
	)

};

export default ListSide;
