import React, {useEffect, useState} from "react";
import "./GGTranslate.css";

let counter = 0;
export const GGTranslate = () => {
	// const [counter, setCounter] = useState(0);
	// const googleTranslateElementInit = () => {
	// 	if(counter === 0) {
	// 		new window.google.translate.TranslateElement(
	// 			{
	// 				pageLanguage: "en",
	// 				autoDisplay: false
	// 			},
	// 			"google_translate_element"
	// 		);
	// 		counter += 1;
	// 	}
	//
	// };
	// useEffect(() => {
	// 	let addScript = document.createElement("script");
	// 	addScript.setAttribute(
	// 		"src",
	// 		"//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
	// 	);
	// 	document.body.appendChild(addScript);
	// 	window.googleTranslateElementInit = googleTranslateElementInit;
	// }, []);

	return null

	// return (
	// 	<>
	// 		<div style={{
	// 			position: 'fixed',
	// 			bottom: 15,
	// 			left: 20
	// 		}} id="google_translate_element"></div>
	// 	</>
	// );
}
