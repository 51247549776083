import feedbackConstants from "../constants/FeedbackConstants";

export default function (
	state = {
		feedbacks: [],
		loadings: {
			get: false,
			add: false,
		}
	},
	action
) {
	switch (action.type) {
		case feedbackConstants.GET_FEEDBACK_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					get: true
				}
			};
		case feedbackConstants.GET_FEEDBACK_SUCCESS:
			return { ...state,
				feedbacks: action.payload,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case feedbackConstants.GET_FEEDBACK_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case feedbackConstants.ADD_FEEDBACK_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					add: true
				}
			};
		case feedbackConstants.ADD_FEEDBACK_SUCCESS:
			return {
				...state,
				feedbacks: [...state.feedbacks, action.payload],
				loadings: { ...state.loadings,
					add: false
				}
			};
		case feedbackConstants.ADD_FEEDBACK_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					add: false
				}
			};
		default:
			return state;
	}
}
