import styled from "styled-components";

const SubTitle = styled.h4 `
	font-family: Ubuntu;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	color: #000000;
	text-align: left;
`;

export default SubTitle;
