import {defineDocumentHost, defineHost, getBaseHost} from "../utils/ConfigUtils";

export const BASE_URL = getBaseHost(process.env.REACT_APP_MYWISHES_ENV);

export const URL = defineHost(process.env.REACT_APP_MYWISHES_ENV);
export const URL_DOCUMENT = defineDocumentHost(process.env.REACT_APP_MYWISHES_ENV);

export const ENV_MYWISHES = process.env.REACT_APP_MYWISHES_ENV;

export const JWT_LOCAL_STORAGE = "my-wishes-app-jwt";
export const ACCEPT_COOKIES_LOCAL_STORAGE = "my-wishes-app-cookies-agreement";
export const HAS_EVER_LOGGED_FROM_MOBILE_LOCAL_STORAGE = "my-wishes-has-ever-logged-from-mobile";
