const digitalLegacyConstants = {
	GET_SCHEDULED_MESSAGE: "GET_SCHEDULED_MESSAGE",
	ADD_SCHEDULED_MESSAGE: "ADD_SCHEDULED_MESSAGE",
	DELETE_SCHEDULED_MESSAGE: "DELETE_SCHEDULED_MESSAGE",
	UPDATE_SCHEDULED_MESSAGE: "UPDATE_SCHEDULED_MESSAGE"
};

export const MAX_SCHEDULED_MESSAGES = 10;

export default digitalLegacyConstants;
