import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

const ScrollToTop = ({ history }) => {
	useEffect(() => {
		const listener = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return listener
	}, []);

	return null;
}

export default withRouter(ScrollToTop);
