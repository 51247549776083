import styled from "styled-components";

const BackWrapper = styled.div`
	position: relative;
	margin: 0 auto;
	margin-top: 8rem;
	max-width: 760px;
	min-height: 400px;
	display: flex;
	flex-direction: row;
	align-items: center;
	background: #ffffff;
	border-radius: 16px;
	box-shadow: 0px 0px 77px -5px rgba(0,0,0,0.4);
	padding: 36px;
`;

export default BackWrapper;
