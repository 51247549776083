const funeralConstants = {
	GET_FUNERAL_REQUEST: "GET_FUNERAL_REQUEST",
	GET_FUNERAL_SUCCESS: "GET_FUNERAL_SUCCESS",
	GET_FUNERAL_FAILED: "GET_FUNERAL_FAILED",
	ADD_FUNERAL_REQUEST: "ADD_FUNERAL_REQUEST",
	ADD_FUNERAL_SUCCESS: "ADD_FUNERAL_SUCCESS",
	ADD_FUNERAL_FAILED: "ADD_FUNERAL_FAILED",
}

export default funeralConstants;
