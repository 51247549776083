import axios from "axios";
import {
	BASE_URL,
	JWT_LOCAL_STORAGE,
	URL_DOCUMENT,
} from "../shared/constants/HostConstants";
export const axiosInstance = axios.create({
	baseURL: BASE_URL,
});

export const axiosDocumentInstance = axios.create({
	baseURL: URL_DOCUMENT,
});

export const axiosRaw = axios;

axiosInstance.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// if (error.response.status === 401) {
		//     // return Promise.resolve(null)
		// }
		return Promise.reject(error);
	}
);

axiosDocumentInstance.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// if (error.response.status === 401) {
		//     // return Promise.resolve(null)
		// }
		return Promise.reject(error);
	}
);

axiosDocumentInstance.interceptors.request.use(async (config) => {
	const value = localStorage.getItem(JWT_LOCAL_STORAGE);
	if (value !== null) {
		config.headers["Authorization"] = `Bearer ${value}`;
	}
	return config;
});
