const advancePlanConstants = {
	GET_STGILES_ACP: "GET_STGILES_ACP",
	ADD_STGILES_ACP: "ADD_STGILES_ACP",
	ADD_STGILES_ACP_REQUEST: "ADD_STGILES_ACP_REQUEST",
	ADD_STGILES_ACP_FAILED: "ADD_STGILES_ACP_FAILED",
	DOWNLOAD_ACP_REQUEST: "DOWNLOAD_ACP_REQUEST",
	DOWNLOAD_ACP_SUCCESS: "DOWNLOAD_ACP_SUCCESS",
	DOWNLOAD_ACP_FAILED: "DOWNLOAD_ACP_FAILED",
	SHARE_ACP_REQUEST: "SHARE_ACP_REQUEST",
	SHARE_ACP_SUCCESS: "SHARE_ACP_SUCCESS",
	SHARE_ACP_FAILED: "SHARE_ACP_FAILED",
	FETCH_POSTURAL_DOCUMENTS_REQUEST: "FETCH_POSTURAL_DOCUMENTS_REQUEST",
	FETCH_POSTURAL_DOCUMENTS_FAILED: "FETCH_POSTURAL_DOCUMENTS_FAILED",
	FETCH_POSTURAL_DOCUMENTS_SUCCESS: "FETCH_POSTURAL_DOCUMENTS_SUCCESS",
	REMOVE_POSTURAL_DOCUMENTS_REQUEST: "REMOVE_POSTURAL_DOCUMENTS_REQUEST",
	REMOVE_POSTURAL_DOCUMENTS_FAILED: "REMOVE_POSTURAL_DOCUMENTS_FAILED",
	REMOVE_POSTURAL_DOCUMENTS_SUCCESS: "REMOVE_POSTURAL_DOCUMENTS_SUCCESS"
};

export default advancePlanConstants;
