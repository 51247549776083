import userConstants from "../constants/UserConstants";
import _ from "lodash";

export default function(
	state = {
		username: "",
		avatar: "",
		isdead: false,
		user_executors: [],
		region: {},
		user_profile: {
			bod: null,
			bio: "",
			visibility: false,
			full_name: "",
			profile_banner: "",
			user_addresses: [
				{
					address: "",
					city: "",
					country: "",
					postal_code: ""
				}
			]
		},
		socials: []
	},
	action
) {
	switch (action.type) {
		case userConstants.GET_USER_INFO:
			return { ...state, ...action.payload };
		case userConstants.EDIT_USER_INFO:
			return { ...state, ...action.payload };
		case userConstants.EDIT_BANNER:
			return {
				...state,
				user_profile: {
					...state.user_profile,
					profile_banner: action.payload
				}
			};
		case userConstants.GET_USER_SOCIAL_PROFILE:
			return {
				...state,
				socials: action.payload
			};
		case userConstants.EDIT_AVATAR:
			return {
				...state,
				avatar: action.payload
			};
		case userConstants.EDIT_USER_SOCIAL_PROFILE:
			return {
				...state,
				socials: action.payload
			};
		case userConstants.EDIT_USER_PRIVACY_PROFILE:
			return {
				...state,
				user_profile: {
					...state.user_profile,
					visibility: _.get(action, "payload.visibility", false)
				}
			};
		default:
			return state;
	}
}
