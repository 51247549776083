import SignupConstants from "../constants/SignupConstants";

export default function(
	state = {
		loading: false
	},
	action
) {
	switch (action.type) {
		case SignupConstants.SIGNUP_REQUEST: 
			return { ...state, loading: true };
		case SignupConstants.SIGNUP_SUCCESS:
			return { ...state, loading: false };
		case SignupConstants.SIGNUP_FAILURE:
			return { ...state, loading: false };
		default:
			return state;
	}
}
