import ScheduledConstants from "../constants/ScheduledConstants";

export default function(
	state = {
		scheduledMessage: []
	},
	action
) {
	switch (action.type) {
		case ScheduledConstants.GET_SCHEDULED_MESSAGE:
			// console.log(action.payload)
			return { ...state, scheduledMessage: action.payload };
		case ScheduledConstants.ADD_SCHEDULED_MESSAGE:
			return {
				...state,
				scheduledMessage: [...state.scheduledMessage, action.payload]
			};
		case ScheduledConstants.DELETE_SCHEDULED_MESSAGE:
			return {
				...state,
				scheduledMessage: [
					...state.scheduledMessage.slice(0, action.payload),
					...state.scheduledMessage.slice(action.payload + 1)
				]
			};
		case ScheduledConstants.UPDATE_SCHEDULED_MESSAGE:
			return {
				...state,
				scheduledMessage: [
					...state.scheduledMessage.slice(0, action.payload.index),
					{ ...action.payload.updatedItem },
					...state.scheduledMessage.slice(action.payload.index + 1)
				]
			};
		default:
			return state;
	}
}
