import PropTypes from "prop-types";

export const ModalType = {
	title: PropTypes.any.isRequired,
	visible: PropTypes.bool.isRequired,
	centered: PropTypes.bool,
	closeable: PropTypes.bool,
	width: PropTypes.number,
	footer: PropTypes.any,
	maskCloseable: PropTypes.bool,
	children: PropTypes.element.isRequired
};
