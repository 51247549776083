import Styled from "styled-components";

export const StyledModalText = Styled.div`
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #3e3e3e;
    line-height: 32px;
`;
