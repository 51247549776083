import { ProductLastWillMywishesAPIConstants } from "./api";

const ProductLastWillMywishesConstants = {
	MW_PRODUCT_REQUEST: "MW_PRODUCT_REQUEST",
	MW_PRODUCT_SUCCESS: "MW_PRODUCT_SUCCESS",
	MW_PRODUCT_FAILED: "MW_PRODUCT_FAILED",
};

export {
	ProductLastWillMywishesConstants,
	ProductLastWillMywishesAPIConstants,
};
