const ProductLastWillMywishesAPIConstants = {
	mywishes: {
		lastWill: "/products",
	},
	stripe: {
		createIntent: "/payments/stripe/intents",
	},
};

export { ProductLastWillMywishesAPIConstants };
