import jwt from "jsonwebtoken";
import { Modal } from "antd";

import { JWT_LOCAL_STORAGE } from "../constants/HostConstants";
import LoadingConstants from "../constants/LoadingConstants";
import AlertConstants from "../constants/AlertConstants";
import LogoLarge from "../components/logo/logoLarge";

const confirm = Modal.confirm;

export const decodeUserId = token => {
	const userId = jwt.decode(token);
	return userId;
};

export const parseHttpHeaders = httpHeaders => {
	return httpHeaders
		.split("\n")
		.map(x => x.split(/: */, 2))
		.filter(x => x[0])
		.reduce((ac, x) => {
			ac[x[0]] = x[1];
			return ac;
		}, {});
};

export const requestErrorHandler = (error, dispatch, history) => {
	const { response } = error;
	if (!response || !response.status) {
		dispatch({
			type: AlertConstants.SHOW_ALERT,
			message: "An error has ocurred, please try again"
		});
		dispatch({
			type: LoadingConstants.CLOSE_LOADING
		});
		return;
	}
	switch (response.status) {
		case 401:
			localStorage.removeItem(JWT_LOCAL_STORAGE);
			dispatch({
				type: AlertConstants.SHOW_ALERT,
				message: "Your session has ended, please sign in again"
			});
			dispatch({
				type: LoadingConstants.CLOSE_LOADING
			});
			// TODO: dispatch error message here
			history.push("/login");
			break;
		case 400:
			dispatch({
				type: AlertConstants.SHOW_ALERT,
				message:
					response.data.user_message ||
					"An error has ocurred, please try again"
			});
			dispatch({
				type: LoadingConstants.CLOSE_LOADING
			});
			break;
		case 404:
			dispatch({
				type: AlertConstants.SHOW_ALERT,
				message:
					response.data.user_message ||
					"An error has ocurred, please try again"
			});
			dispatch({
				type: LoadingConstants.CLOSE_LOADING
			});
			break;
		default:
			dispatch({
				type: AlertConstants.SHOW_ALERT,
				message: "An error has ocurred, please try again"
			});
			dispatch({
				type: LoadingConstants.CLOSE_LOADING
			});
	}
};

export const handleModalDelete = deleteHandler => {
	confirm({
		title: "Are you sure you would like to delete this item?",
		okText: "Yes, I am sure",
		okType: "danger",
		cancelText: "Cancel",
		onOk() {
			deleteHandler();
		}
	});
};
