export function getBaseHost(ENV) {
	switch (ENV) {
		case "development":
			return "http://localhost:3020";
		case "staging":
			return "https://api.staging.mywishes.co.uk";
		default:
			return "https://api.mywishes.co.uk";
	}
}

export function defineHost(ENV) {
	return getBaseHost(ENV) + '/v1';
}

export function defineDocumentHost(ENV) {
	switch (ENV) {
		case "development":
			return "http://localhost:3021/v1";
		case "staging":
			return "https://mongo.staging.mywishes.co.uk/v1";
		default:
			return "https://mongo.mywishes.co.uk/v1";
	}
}

