import willConstants from "../constants/WillConstants";

export default function(
	state = {
		wills: [],
		loadings: {
			save: false,
			get: false
		},
		shareWillLoading: false,
	},
	action
) {
	switch (action.type) {
		case willConstants.GET_WILL_REQUEST:
			return { ...state, loadings: { ...state.loadings, get: true } };
		case willConstants.GET_WILL_SUCCESS:
			return {
				...state,
				wills: action.payload,
				loadings: { ...state.loadings, get: false }
			};
		case willConstants.GET_WILL_FAILED:
			return { ...state, loadings: { ...state.loadings, get: false } };
		case willConstants.ADD_WILL_REQUEST:
			return {
				...state,
				loadings: { ...state.loadings, save: true }
			};
		case willConstants.ADD_WILL_SUCCESS:
			// console.log("ADD_WILL_SUCCESS");
			// console.log(action.payload);
			return {
				...state,
				wills: action.payload,
				loadings: { ...state.loadings, save: false }
			};
		case willConstants.ADD_WILL_FAILED:
			return {
				...state,
				loadings: { ...state.loadings, save: false }
			};
		case willConstants.SHARE_WILL_REQUEST:
			return { ...state, shareWillLoading: true };
		case willConstants.SHARE_WILL_SUCCESS:
			return { ...state, shareWillLoading: false };
		case willConstants.SHARE_WILL_FAILED:
			return { ...state, shareWillLoading: false };
		default:
			return state;
	}
}
