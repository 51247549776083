import React from "react";
import { Layout } from "antd";

const { Sider } = Layout;

const CollapsibleSide = props => {
	const { children, collapsed } = props;
	return (
		<Sider {...props} trigger={null} collapsible collapsed={collapsed}>
			{children}
		</Sider>
	);
};

export default CollapsibleSide;
