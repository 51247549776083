import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Layout, Icon, Dropdown, Avatar, Menu, Button } from "antd";

import { JWT_LOCAL_STORAGE } from "../../constants/HostConstants";
import { signOutUser } from "../../../features/Login/actions/LoginActions";
import AvatarDefault from "../../../static/assets/img/avatar-default.png";

const { Header } = Layout;

const CustomIcon = styled(Icon)`
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
	color: #fff;
`;

const IconHoverable = styled(CustomIcon)`
	${CustomIcon}:hover & {
		color: #1890ff;
	}
`;

const ProfileIcon = styled.div`
	float: right;
	margin-right: 24px;
	display: flex;
	align-items: center;
`;

const CustomName = styled.span`
	margin-left: 10px;
	color: #fff;
`;

const CustomSpan = styled.span`
	color: rgba(0, 0, 0, 0.65);
	margin-left: 8px;
`;

class HeaderNav extends React.Component {
	constructor(props) {
		super(props);

		this.logout = this.logout.bind(this);
	}

	logout() {
		const { history, signOutUser } = this.props;
		// delete the token from local storage first
		localStorage.removeItem(JWT_LOCAL_STORAGE);
		signOutUser();
		window.location.replace("/login");
	}
	render() {
		const {
			avatar,
			username,
			handleNavCollapse,
			isNavCollapsed,
			isListCollapsed,
			listTrigger,
			handleSideCollapse,
			history
		} = this.props;
		const menu = (
			<Menu
				onClick={e => {
					switch (e.key) {
						case "0":
							history.push("/my-profile");
							break;
						case "1":
							this.logout();
							break;
						default:
							return;
					}
				}}
			>
				<Menu.Item key="0">
					<Icon type="user" />
					<CustomSpan>Profile</CustomSpan>
				</Menu.Item>
				<Menu.Item key="1">
					<Icon type="poweroff" />
					<CustomSpan>Logout</CustomSpan>
				</Menu.Item>
			</Menu>
		);
		return (
			<Header style={{ background: "#44617D", padding: 0 }}>
				<IconHoverable
					type={isNavCollapsed ? "menu-unfold" : "menu-fold"}
					onClick={handleNavCollapse}
				/>
				<ProfileIcon>
					{listTrigger ? (
						<Button
							onClick={handleSideCollapse}
							style={{ marginRight: 16 }}
							type="primary"
						>
							Activity Log
							<Icon type={isListCollapsed ? "left" : "right"} />
						</Button>
					) : null}
					<Dropdown overlay={menu}>
						<div>
							<Avatar src={avatar || AvatarDefault} />
							<CustomName>{username}</CustomName>
						</div>
					</Dropdown>
				</ProfileIcon>
			</Header>
		);
	}
}

const mapStateToProps = ({ userReducers }) => {
	return userReducers;
};

export default withRouter(connect(mapStateToProps, { signOutUser })(HeaderNav));
