import advancePlan from "../constants/ACPConstants";

export default function(
	state = {
		advancePlan: []
	},
	action
) {
	switch (action.type) {
		case advancePlan.GET_ADVANCE:
			return { ...state, advancePlan: action.payload };
		case advancePlan.ADD_ADVANCE:
			return {
				...state,
				advancePlan: [action.payload, ...state.advancePlan]
			};
		default:
			return state;
	}
}
