import FeedbackConstant from "../constants/FeedbackConstant";

export function FeedbackCloseModalAction() {
	return async function(dispatch) {
		dispatch({
			type: FeedbackConstant.FEEDBACK_CLOSE_MODAL,
			modalChildren: null,
			modalFooter: null
		});
	};
}

export function FeedbackShowModalAction(chidlren, footer) {
	return async function(dispatch) {
		dispatch({
			type: FeedbackConstant.FEEDBACK_SHOW_MODAL,
			modalChildren: chidlren,
			modalFooter: footer
		});
	};
}
