export const resolveGAMeasurementID = (ENV) => {
	switch (ENV) {
		case "production":
			return "G-MTZE35SX1V";
		default:
			return "G-1CV9WD9P46";
	}
};

const GA_MEASUREMENT_ID = resolveGAMeasurementID(process.env.REACT_APP_MYWISHES_ENV);

const defaultSnippet = () => {
	console.log('start script');
	if (window.gtag !== undefined) {
		return window.gtag
	}

	window.dataLayer = window.dataLayer || [];

	function gtag() {
		window.dataLayer.push(arguments);
	}

	window.gtag = gtag;
	gtag("js", new Date());
	// We don't send a page view because that's being entirely managed through
	// the 'usePageView' hook.
	gtag("config", GA_MEASUREMENT_ID, {
		send_page_view: false,
	})

	const head = document.head || document.getElementsByTagName("head")[0]
	const script = document.createElement("script")
	script.type = "text/javascript"
	script.async = true
	script.src = `https://googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`

	head.appendChild(script)

	return gtag;
}

export let sendGATag = defaultSnippet();

export const sendPageView = (pageTitle) => {
	const location = window.location;
	pageTitle = pageTitle ?? document.title;
	sendGATag("event", "page_view", {
		page_title: pageTitle,
		page_location: location.origin + location.pathname,
	});
}

export const setConfig = (prop, configs) => {
	sendGATag("set", prop, configs);
}

export const setUserID = (userID, otherConfigs = {}) => {
	sendGATag('config', GA_MEASUREMENT_ID, {
		'user_id': userID,
		user_properties: otherConfigs
	});
}
