import AlertConstants from "../constants/AlertConstants";

export function hideMessage() {
	return async function(dispatch) {
		dispatch({
			type: AlertConstants.CLOSE_ALERT
		});
	};
}

export function showMessage(message) {
	return async function(dispatch) {
		// console.log(message);
		dispatch({
			type: AlertConstants.SHOW_ALERT,
			message: message
		});
	};
}

export function showWillPaymentModal() {
	return async function(dispatch) {
		dispatch({
			type: AlertConstants.SHOW_WILL_PAYMENT_MODAL
		});
	};
}

export function hideWillPaymentModal() {
	return async function(dispatch) {
		dispatch({
			type: AlertConstants.HIDE_WILL_PAYMENT_MODAL
		});
	};
}
