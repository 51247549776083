import styled from "styled-components";

export const BlogBgWrapper = styled.div`
	position: relative;
	height: 300px;
	margin-bottom: 20px;

	& > .text {
		z-index: 100;
		position: relative;
		font-size: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-weight: 700;
		color: #fff;
	}
`

export const BlogBgTop = styled.div`
	position: absolute;
	width: 100%;
	height: 300px;
	background: url('https://learn.mywishes.co.uk/wp-content/uploads/2020/09/Background-MyWishes.jpg');
	width: 100%;
	height: 100%;
`

export const PostStyled = styled.div`
	background: #fff;
    border-radius: 16px;
    box-shadow: 0 0 10px rgb(46 59 125 / 23%);
	transition: all 0.5s ease 0s;

	&:hover {
		transform: scale(0.94);
		cursor:pointer;
	}

	& > .post-info {
		padding: 16px;
		position: relative;

		& > .post-time {
			font-size: 10px;
		}

		& > .post-title {
			font-weight: 600;
		}

		& > span.material-symbols-rounded {
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;
			font-size: 20px;
		}

		& > .post-author {
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: 500;
			& > img {
				border-radius: 50%;
			}
		}
	}
	
`
export const PostFeatureImgStyled = styled.div`
	height: 170px;
	background-size: cover;
	width: 100%;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	background-repeat: no-repeat;
	background-position: center;
`
