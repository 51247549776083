import { SaveStripePaymentTransactionAPIConstants } from "./api";
const CreateStripePaymentConfirmConstants = {
	MW_STRIPE_PAYMENT_REQUEST: "MW_STRIPE_PAYMENT_REQUEST",
	MW_STRIPE_PAYMENT_SUCCESS: "MW_STRIPE_PAYMENT_SUCCESS",
	MW_STRIPE_PAYMENT_FAILED: "MW_STRIPE_PAYMENT_FAILED",
};

const CreateStripePaymentIntentConstants = {
	MW_STRIPE_INTENT_REQUEST: "MW_STRIPE_INTENT_REQUEST",
	MW_STRIPE_INTENT_SUCCESS: "MW_STRIPE_INTENT_SUCCESS",
	MW_STRIPE_INTENT_FAILED: "MW_STRIPE_INTENT_FAILED",
};

const SaveStripePaymentTransactionConstants = {
	MW_STRIPE_SAVE_TRANSACTION_REQUEST: "MW_STRIPE_SAVE_TRANSACTION_REQUEST",
	MW_STRIPE_SAVE_TRANSACTION_SUCCESS: "MW_STRIPE_SAVE_TRANSACTION_SUCCESS",
	MW_STRIPE_SAVE_TRANSACTION_FAILED: "MW_STRIPE_SAVE_TRANSACTION_FAILED",
};

export {
	CreateStripePaymentConfirmConstants,
	CreateStripePaymentIntentConstants,
	SaveStripePaymentTransactionConstants,
	SaveStripePaymentTransactionAPIConstants,
};

