import Styled from "styled-components";

export const StyledContainerFluid = Styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0;
  margin: 0;
`;

export const StyledContainer = Styled.div`
    display: flex;
    flex-grow: 1;
    @media(min-width: 320px){
      margin: 2.5rem;
    }
    @media(min-width: 768px){
      margin: 4.5rem;
    }
    @media(min-width: 1440px){
      margin: 8rem 14rem;
    }
`;
