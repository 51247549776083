import playlistConstants from "../constants/PlaylistConstants";

export default function(
	state = {
		playlists: []
	},
	action
) {
	switch (action.type) {
		case playlistConstants.GET_PLAYLIST:
			return { ...state, playlists: action.payload };
		case playlistConstants.ADD_PLAYLIST:
			return { ...state, playlists: [...state.playlists, action.payload] };
		case playlistConstants.EDIT_PLAYLIST:
			return {
				...state,
				playlists: [
					...state.playlists.slice(0, action.payload.index),
					{ ...action.payload.updatedData },
					...state.playlists.slice(action.payload.index + 1)
				]
			};
		case playlistConstants.DELETE_PLAYLIST:
			return {
				...state,
				playlists: [
					...state.playlists.slice(0, action.payload),
					...state.playlists.slice(action.payload + 1)
				]
			};
		default:
			return state;
	}
}
