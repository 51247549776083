import accountConstants from "../constants/AccountConstants";

export default function (
	state = {
		accounts: []
	},
	action
) {
	switch (action.type) {
		case accountConstants.GET_ALL_ACCOUNTS:
			return { ...state,
				accounts: action.payload
			};
		case accountConstants.ADD_ACCOUNT:
			return {
				...state,
				accounts: [action.payload, ...state.accounts]
			};
		case accountConstants.UPDATE_ACCOUNT:
			return {
				...state,
				accounts: [
					...state.accounts.slice(0, action.payload.index),
					{ ...action.payload.updatedData },
					...state.accounts.slice(action.payload.index + 1)
				]
			};
		case accountConstants.DELETE_ACCOUNT:
			return {
				...state,
				accounts: [
					...state.accounts.slice(0, action.payload),
					...state.accounts.slice(action.payload + 1)
				]
			};
		default:
			return state;
	}
}
