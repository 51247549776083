import styled from "styled-components";

const Title = styled.h3`
	flex: 1;
	margin: 32px 0;
	font-size: 8em;
	font-weight: 500;
	color: #5882C2;
`;

export default Title;
