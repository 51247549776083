import React from "react";
import styled from "styled-components";

import CentralizedSpin from "./Spin";
import CustomizedIcon from "./Icon";

const Container = styled.div`
	height: ${props => props.height ? props.height : "50vh"} !important;
`;

const Loading = props => {
	const { size, withContainer, height } = props;
	return withContainer ? (
		<Container height={height}>
			<CentralizedSpin relative="true" indicator={<CustomizedIcon type="loading" size={size}/>}/>
		</Container>
	) : <CentralizedSpin indicator={<CustomizedIcon type="loading" size={size}/>}/>
} 

export default Loading;
