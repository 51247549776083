import {
	CreateStripePaymentIntentConstants,
	CreateStripePaymentConfirmConstants,
	SaveStripePaymentTransactionConstants,
} from "../constants";

const StripePaymentMywishesReducer = (
	state = {
		items: [],
		intent: {
			isButtonPaymentEnable: false,
			clientInvoiceId: null,
			clientSecret: null,
			clientPublishableKey: null,
			isProcessingPayment: false,
			isPaymentSuccess: false,
		},
	},
	action
) => {
	switch (action.type) {
		case CreateStripePaymentIntentConstants.MW_STRIPE_INTENT_REQUEST:
			return {
				...state,
				intent: { ...state.intent, isButtonPaymentEnable: false },
			};
		case CreateStripePaymentIntentConstants.MW_STRIPE_INTENT_SUCCESS:
			return {
				...state,
				intent: { ...state.intent, ...action.intent },
				items: action.items,
			};
		case CreateStripePaymentIntentConstants.MW_STRIPE_INTENT_FAILED:
			return {
				...state,
				intent: { ...state.intent, isButtonPaymentEnable: false },
			};
		case CreateStripePaymentConfirmConstants.MW_STRIPE_PAYMENT_REQUEST:
			return {
				...state,
				intent: {
					...state.intent,
					isProcessingPayment: true,
					isButtonPaymentEnable: false,
				},
			};
		case CreateStripePaymentConfirmConstants.MW_STRIPE_PAYMENT_SUCCESS:
			return {
				...state,
				intent: {
					clientInvoiceId: null,
					clientSecret: null,
					clientPublishableKey: null,
					isProcessingPayment: false,
					isPaymentSuccess: true,
					isButtonPaymentEnable: true,
				},
			};
		case CreateStripePaymentConfirmConstants.MW_STRIPE_PAYMENT_FAILED:
			return {
				...state,
				intent: {
					...state.intent,
					isProcessingPayment: false,
					isPaymentSuccess: false,
					isButtonPaymentEnable: true,
				},
			};
		case SaveStripePaymentTransactionConstants.MW_STRIPE_SAVE_TRANSACTION_REQUEST:
			return {
				...state,
			};
		case SaveStripePaymentTransactionConstants.MW_STRIPE_SAVE_TRANSACTION_SUCCESS:
			return {
				...state,
			};
		case SaveStripePaymentTransactionConstants.MW_STRIPE_SAVE_TRANSACTION_FAILED:
			return {
				...state,
			};
		default:
			return state;
	}
};

export { StripePaymentMywishesReducer };
