const RegisterStgilesConstant = {
	STGILES_REGISTER_REQUEST: "STGILES_REGISTER_REQUEST",
	STGILES_REGISTER_SUCCESS: "STGILES_REGISTER_SUCCESS",
	STGILES_REGISTER_FAILED: "STGILES_REGISTER_FAILED",

	FETCH_USER_PROFESSIONAL_REQUEST: "FETCH_USER_PROFESSIONAL_REQUEST",
	FETCH_USER_PROFESSIONAL_SUCCESS: "FETCH_USER_PROFESSIONAL_SUCCESS",
	FETCH_USER_PROFESSIONAL_FAILED: "FETCH_USER_PROFESSIONAL_FAILED",

	FETCH_COUNTRY_LIST_REQUEST: "FETCH_COUNTRY_LIST_REQUEST",
	FETCH_COUNTRY_LIST_SUCCESS: "FETCH_COUNTRY_LIST_SUCCESS",
	FETCH_COUNTRY_LIST_FAILED: "FETCH_COUNTRY_LIST_FAILED"
};

const UserMessage = {
	InternalServerError:
		"Something isn’t working like it should. Please refresh the page or visit another page on MyWishes. If the issue persists please come back again soon and it will be fixed"
};

export { RegisterStgilesConstant, UserMessage };
