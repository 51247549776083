import { RegisterStgilesConstant } from "../constants";

const RegisterStgilesReducer = (
	state = {
		isLoading: false,
		userProfessionals: [],
		countryList: []
	},
	action
) => {
	switch (action.type) {
		case RegisterStgilesConstant.STGILES_REGISTER_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case RegisterStgilesConstant.STGILES_REGISTER_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case RegisterStgilesConstant.STGILES_REGISTER_FAILED:
			return { ...state, isLoading: false };
		case RegisterStgilesConstant.FETCH_USER_PROFESSIONAL_REQUEST:
			return {
				...state,
				userProfessionals: []
			};
		case RegisterStgilesConstant.FETCH_USER_PROFESSIONAL_SUCCESS:
			return {
				...state,
				userProfessionals: action.userProfessionals
			};
		case RegisterStgilesConstant.FETCH_USER_PROFESSIONAL_FAILED:
			return {
				...state,
				userProfessionals: []
			};
		case RegisterStgilesConstant.FETCH_COUNTRY_LIST_REQUEST:
			return {
				...state,
				countryList: []
			};
		case RegisterStgilesConstant.FETCH_COUNTRY_LIST_SUCCESS:
			return {
				...state,
				countryList: action.countryList
			};
		case RegisterStgilesConstant.FETCH_USER_PROFESSIONAL_FAILED:
			return {
				...state,
				countryList: []
			};
		default:
			return state;
	}
};

export { RegisterStgilesReducer };
