import bucketConstants from "../constants/BucketConstants";

export default function (
	state = {
		buckets: [],
		loadings: {
			get: false,
			add: false,
			edit: null,
			delete: null,
		}
	},
	action
) {
	switch (action.type) {
		case bucketConstants.GET_BUCKET_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					get: true
				}
			};
		case bucketConstants.GET_BUCKET_SUCCESS:
			return { ...state,
				buckets: action.payload,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case bucketConstants.GET_BUCKET_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case bucketConstants.ADD_BUCKET_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					add: true
				}
			};
		case bucketConstants.ADD_BUCKET_SUCCESS:
			return { ...state,
				buckets: [...state.buckets, action.payload],
				loadings: { ...state.loadings,
					add: false
				}
			};
		case bucketConstants.ADD_BUCKET_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					add: false
				}
			};
		case bucketConstants.EDIT_BUCKET_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					edit: action.payload
				}
			};
		case bucketConstants.EDIT_BUCKET_SUCCESS:
			return {
				...state,
				buckets: [
					...state.buckets.slice(0, action.payload.index),
					{ ...action.payload.updatedData
					},
					...state.buckets.slice(action.payload.index + 1)
				],
				loadings: { ...state.loadings,
					edit: null
				}
			};
		case bucketConstants.EDIT_BUCKET_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					edit: null
				}
			};
		case bucketConstants.DELETE_BUCKET_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					delete: action.payload
				}
			};
		case bucketConstants.DELETE_BUCKET_SUCCESS:
			return {
				...state,
				buckets: [
					...state.buckets.slice(0, action.payload),
					...state.buckets.slice(action.payload + 1)
				],
				loadings: { ...state.loadings,
					delete: null
				}
			};
		case bucketConstants.DELETE_BUCKET_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					delete: null
				}
			};
		default:
			return state;
	}
}
