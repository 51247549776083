import React from "react";
import { RootType } from "./type";
import { StyledRoot } from "./styled";

// NEW VEERSION //
const Root = props => {
	const { children, style } = props;
	return (
		<StyledRoot id="mw-root" style={style}>
			{children}
		</StyledRoot>
	);
};

Root.propTypes = RootType;
export default Root;
