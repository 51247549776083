import FeedbackConstant from "../constants/FeedbackConstant";

const feedbackState = {
	isModalShow: false,
	modalChildren: null,
	modalFooter: null,
	contentCenter: true,

	isNotificationShow: false,
	notificationChildren: null,
	isSkeletonShow: false,
	skeletonChildren: null,
	isLoadingShow: false
};

const FeedbackReducer = (state = feedbackState, action) => {
	switch (action.type) {
		case FeedbackConstant.FEEDBACK_SHOW_LOADING:
			return { ...state, isLoadingShow: true };
		case FeedbackConstant.FEEDBACK_CLOSE_LOADING:
			return { ...state, isLoadingShow: false };
		case FeedbackConstant.FEEDBACK_SHOW_MODAL:
			return {
				...state,
				contentCenter: action?.contentCenter ?? true,
				isModalShow: true,
				modalChildren: action.children,
				modalFooter: action.footer
			};
		case FeedbackConstant.FEEDBACK_CLOSE_MODAL:
			return {
				...state,
				isModalShow: false,
				modalChildren: null,
				modalFooter: null
			};
		case FeedbackConstant.FEEDBACK_SHOW_NOTIFICATION:
			return {
				...state,
				isNotificationShow: true,
				notificationChildren: action.children
			};
		case FeedbackConstant.FEEDBACK_CLOSE_NOTIFICATION:
			return {
				...state,
				isNotificationShow: false,
				notificationChildren: null
			};
		case FeedbackConstant.FEEDBACK_SHOW_SKELETON:
			return {
				...state,
				isSkeletonShow: true,
				skeletonChildren: action.children
			};
		case FeedbackConstant.FEEDBACK_CLOSE_SKELETON:
			return { ...state, isSkeletonShow: false, skeletonChildren: null };
		default:
			return state;
	}
};

export { FeedbackReducer };
