import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Layout } from "antd";

import LogoWhite from "../../../static/assets/img/mywishes-logo-white.png";

const { Footer } = Layout;

const CustomLink = styled(Link)`
	color: #fff;

	&:hover {
		color: #fff;
	}
`;

const FooterSide = () => {
	const copyRightYear = new Date().getFullYear();

	return (
		<Footer
			style={{
				background: "#44617D",
				padding: 16,
				color: "#fff",
				zIndex: 9,
			}}
		>
			<div className="footer-menu section-secondary">
				<div className="container">
					<div className="row">
						<div className="col-md-4 col-sm-12">
							<b>Features</b>
							<ul className="footer-ul list-type-none">
								<CustomLink to="/my-will-testament">
									<li className="footer-list">
										My Last Will &amp; Testament
									</li>
								</CustomLink>
								<CustomLink to="/my-funeral-wishes">
									<li className="footer-list">
										My Funeral Wishes
									</li>
								</CustomLink>
								<CustomLink to="/my-advance-care-plan">
									<li className="footer-list">
										My Advance Care Plan
									</li>
								</CustomLink>
								<CustomLink to="/my-goodbye-messages">
									<li className="footer-list">
										My Goodbye Message
									</li>
								</CustomLink>
								<CustomLink to="/my-online-accounts">
									<li className="footer-list">
										My Online Accounts
									</li>
								</CustomLink>
								<CustomLink to="/my-bucket-list">
									<li className="footer-list">
										My Bucket List
									</li>
								</CustomLink>
								<CustomLink to="/my-private-notes">
									<li className="footer-list">
										My Private Notes
									</li>
								</CustomLink>
								<CustomLink to="/feedback">
									<li className="footer-list">
										MyWishes Feedback
									</li>
								</CustomLink>
								<CustomLink to="/confirm-deceased">
									<li className="footer-list">
										Register a Death
									</li>
								</CustomLink>
							</ul>
						</div>
						<div className="col-md-4 col-sm-12">
							<b>Company</b>
							<ul className="footer-ul list-type-none">
								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/how-it-works"
									target="_blank"
								>
									<li className="footer-list">
										How It Works
									</li>
								</a>
								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/about"
									target="_blank"
								>
									<li className="footer-list">
										About MyWishes
									</li>
								</a>
								<a
									style={{ color: "#fff" }}
									href="//blog.mywishes.co.uk"
									target="_blank"
								>
									<li className="footer-list">Blog</li>
								</a>
								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/careers"
									target="_blank"
								>
									<li className="footer-list">Career</li>
								</a>
								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/press-kit"
									target="_blank"
								>
									<li className="footer-list">Press Kit</li>
								</a>
								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/contact-us"
									target="_blank"
								>
									<li className="footer-list">Contact Us</li>
								</a>
							</ul>
						</div>

						<div className="col-md-4 col-sm-12">
							<b>Others</b>
							<ul className="footer-ul list-type-none">
								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/terms-of-service"
								>
									<li className="footer-list">
										Terms of Service
									</li>
								</a>

								<a
									style={{ color: "#fff" }}
									href="https://www.mywishes.co.uk/privacy-policy"
								>
									<li className="footer-list">
										Privacy Policy
									</li>
								</a>

								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/security"
								>
									<li className="footer-list">Security</li>
								</a>
								<a
									style={{ color: "#fff" }}
									href="//mywishes.co.uk/cookies"
								>
									<li className="footer-list">
										Cookie Policy
									</li>
								</a>
							</ul>

							<div className="mywishes-logo-footer">
								<img
									className="img-fluid"
									src={LogoWhite}
									alt="mywishes-logo"
									width="120px"
								/>
							</div>
							<br />
							<div className="footer-address">
								Michael Sobell Hospice, <br />
								Mount Vernon Hospital, Gate 3
								<br />
								Northwood HA6 2RN, United Kingdom
							</div>
							<div
								style={{
									display: "flex",
									marginTop: 16,
								}}
							>
								<img
									src="https://s3.eu-west-2.amazonaws.com/mywishes-prod-app/assets/mywishes-le-logo-standard.png"
									width="65px"
									height="65px"
								/>
								<img
									style={{
										marginTop: 12,
										marginLeft: 8,
									}}
									src="https://s3.eu-west-2.amazonaws.com/mywishes-prod-app/assets/mywishes-aws-logo-rgb.jpg"
									width="90px"
									height="45px"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-bottom align-center">
				<div className="pull-right hidden-xs">
					<b>Version 1.2</b>
				</div>
				{"  "}
				<strong>
					Copyright © {copyRightYear}{" "}
					<a href="#" style={{ color: "#fff" }}>
						MyWishes.co.uk
					</a>
					.
				</strong>
				{"  "}
				All rights reserved.
			</div>
		</Footer>
	);
};

export default FooterSide;
