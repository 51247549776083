import giftConstants from "../constants/GiftConstants";

export default function (
	state = {
		gifts: [],
		loadings: {
			get: false,
			add: false,
			edit: false,
			delete: null,
		}
	},
	action
) {
	switch (action.type) {
		case giftConstants.GET_GIFT_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					get: true
				}
			}
		case giftConstants.GET_GIFT_SUCCESS:

			return { ...state,
				gifts: action.payload,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case giftConstants.GET_GIFT_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					get: false
				}
			}
		case giftConstants.ADD_GIFT_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					add: true
				}
			}
		case giftConstants.ADD_GIFT_SUCCESS:
			return { ...state,
				gifts: [...state.gifts, action.payload],
				loadings: { ...state.loadings,
					add: false
				}
			};
		case giftConstants.ADD_GIFT_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					add: false
				}
			}
		case giftConstants.EDIT_GIFT_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					edit: true
				}
			}
		case giftConstants.EDIT_GIFT_SUCCESS:
			return {
				...state,
				gifts: [
					...state.gifts.slice(0, action.payload.index),
					{ ...action.payload.updatedData
					},
					...state.gifts.slice(action.payload.index + 1)
				],
				loadings: { ...state.loadings,
					edit: false
				}
			};
		case giftConstants.EDIT_GIFT_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					edit: false
				}
			}
		case giftConstants.DELETE_GIFT_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					delete: action.payload
				}
			}
		case giftConstants.DELETE_GIFT_SUCCESS:
			return {
				...state,
				gifts: [
					...state.gifts.slice(0, action.payload),
					...state.gifts.slice(action.payload + 1)
				],
				loadings: { ...state.loadings,
					delete: null
				}
			};
		case giftConstants.DELETE_GIFT_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					delete: null
				}
			}
		default:
			return state;
	}
}
