const willConstants = {
	GET_WILL_REQUEST: "GET_WILL_REQUEST",
	GET_WILL_SUCCESS: "GET_WILL_SUCCESS",
	GET_WILL_FAILED: "GET_WILL_FAILED",
	ADD_WILL_REQUEST: "ADD_WILL_REQUEST",
	ADD_WILL_SUCCESS: "ADD_WILL_SUCCESS",
	ADD_WILL_FAILED: "ADD_WILL_FAILED",
	SHARE_WILL_REQUEST: "SHARE_WILL_REQUEST",
	SHARE_WILL_SUCCESS: "SUCCESS_WILL_SUCCESS",
	SHARE_WILL_FAILED: "SHARE_WILL_FAILED"
}

export default willConstants;
