import styled from "styled-components";
import { Spin } from "antd";

const CentralizedSpin = styled(Spin)`
	position: ${props => props.relative ? "relative" : "absolute"} !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%,-50%) !important;
`;

export default CentralizedSpin;
