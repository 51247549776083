import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import Snackbar from "material-ui/Snackbar";
// import LinearProgress from "material-ui/LinearProgress";
import { Modal, Button } from "antd";

import RouterComponents from "./routerComponents";
import * as actions from "../shared/actions/AlertActions";
import LogoLarge from "../shared/components/logo/logoLarge";

// NEW VEERSION //
import * as FeedbackAction from "../shared/actions/FeedbackAction";
import { Modal as NewModal } from "../ui";
import { renderModalFooter } from "./components";

class Routers extends Component {
	render() {
		// console.log("ROUTERS");
		// console.log(this.props);
		const {
			showAlert,
			showLoading,
			alertMessage,
			hideMessage
		} = this.props;

		return (
			<div>
				{/* {showLoading && (
					<LinearProgress
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							width: "100vw",
							zIndex: 2000
						}}
						mode="indeterminate"
						color="red"
					/>
				)} */}
				<Router>
					<RouterComponents />
				</Router>
				{showAlert ? (
					<Modal
						title={<LogoLarge type="black" />}
						visible={showAlert}
						centered={true}
						closable={false}
						destroyOnClose={true}
						width={700}
						zIndex={9999}
						footer={
							<Button onClick={() => hideMessage()}>Close</Button>
						}
					>
						<center style={{ paddingTop: "30px" }}>
							<h4
								dangerouslySetInnerHTML={{
									__html: alertMessage
								}}
							/>
						</center>
					</Modal>
				) : null}

				{/* NEW VERSION */}
				<NewModal
					visible={this.props.FeedbackReducer.isModalShow}
					centered={this.props.FeedbackReducer.contentCenter}
					footer={renderModalFooter(this.props)}
					title={<LogoLarge type="black" />}
				>
					{this.props.FeedbackReducer.modalChildren}
				</NewModal>

				{/* <Snackbar
					open={showAlert}
					onRequestClose={() => hideMessage()}
					autoHideDuration={5000}
					message={alertMessage}
					action="close"
					onActionClick={() => hideMessage()}
				/> */}
			</div>
		);
	}
}

const mapStateToProps = ({
	alertReducers,
	loadingReducers,
	FeedbackReducer
}) => {
	return { ...alertReducers, ...loadingReducers, FeedbackReducer };
};

const mapDispatchToProps = {
	...actions,
	...FeedbackAction
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Routers);
