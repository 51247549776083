import React from "react";
import { Button } from "antd";
import isEmpty from "lodash/isEmpty";

export const renderModalFooter = props => {
	if (!isEmpty(props.FeedbackReducer.modalFooter)) {
		return props.FeedbackReducer.modalFooter;
	}

	return (
		<Button
			onClick={() => {
				props.FeedbackCloseModalAction();
			}}
		>
			Close
		</Button>
	);
};
