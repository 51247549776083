import {
	message
} from "antd";
import ResetPasswordConstants from "../constants/ResetPasswordConstants";

export default function (
	state = {
		loading: false
	},
	action
) {
	switch (action.type) {
		case ResetPasswordConstants.RESET_REQUEST:
			return { ...state,
				loading: true
			};
		case ResetPasswordConstants.RESET_SUCCESS:
			message.success(action.payload);
			return { ...state,
				loading: false
			};
		case ResetPasswordConstants.RESET_FAILURE:
			message.error(action.payload);
			return { ...state,
				loading: false
			};
		default:
			return state;
	}
}
