import "./static/css/App.css";
import React, {useEffect} from "react";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import Routers from "./routers/routers";
import FacebookPixel from "./shared/utils/FacebookPixel";
import {sendPageView} from "./shared/utils/GoogleAnalytics";

function App() {
	useEffect(() => {
		sendPageView();
		FacebookPixel();
		const mywishesENV = process.env.REACT_APP_MYWISHES_ENV;
		if (mywishesENV === "production") {
			Sentry.init({
				dsn: process.env.REACT_APP_SENTRY_DNS,
				integrations: [new Integrations.BrowserTracing()],

				// We recommend adjusting this value in production, or using tracesSampler
				// for finer control
				tracesSampleRate: 1.0,
			});
		}
	}, [])

	return (
		// <MuiThemeProvider>
		<Routers/>
		// </MuiThemeProvider>
	);
}

export default App;
