import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import LoginReducers from "../../features/Login/reducers/LoginReducers";
import SignupReducers from "../../features/Signup/reducers/SignupReducers";
import digitalLegacyReducers from "../../features/GoodbyeScheduled/Goodbye/reducers/GoodbyeReducers";
import ScheduledReducers from "../../features/GoodbyeScheduled/Scheduled/reducers/ScheduledReducers";
import EventReducers from "../../features/FuneralWishesPlaylist/Event/reducers/EventReducers";
import bucketReducers from "../../features/BucketList/reducers/BucketReducers";
import noteReducers from "../../features/PrivateNote/reducers/NoteReducers";
import playlistReducers from "../../features/FuneralWishesPlaylist/FuneralPlaylist/reducers/PlaylistReducer";
import userReducers from "../../features/Profile/reducers/UserReducers";
import alertReducers from "./AlertReducers";
import loadingReducers from "./LoadingReducers";
import funeralReducers from "../../features/FuneralWishesPlaylist/FuneralWishes/reducers/FuneralReducers";
import advancePlanReducers from "../../features/AdvanceCarePlan/reducers/ACPReducers";
import STGilesACPReducers from "../../features/STGilesACP/reducers/ACPReducers";
import willReducers from "../../features/LastWillGift/LastWillTestament/reducers/WillReducers";
import accountReducers from "../../features/OnlineAccount/reducers/AccountReducers";
import feedbackReducers from "../../features/Feedback/reducers/FeedbackReducers";
import giftReducers from "../../features/LastWillGift/GiftBeneficiaries/reducers/GiftReducers";
import ForgotPasswordReducers from "../../features/ForgotPassword/reducers/ForgotPasswordReducers";
import ResetPasswordReducers from "../../features/ResetPassword/reducers/ResetPasswordReducers";

import LoginConstants from "../../features/Login/constants/LoginConstants";

// NEW VERSION //
import { LoginMywishesReducer } from "../../app/features/login/reducers";
import { FeedbackReducer } from "./FeedbackReducer";
import { RegisterStgilesReducer } from "../../app/features/register/reducers";
import { ProductLastWillMywishesReducer } from "../../app/features/product/reducers";
import { StripePaymentMywishesReducer } from "../../app/features/payment/reducers";
import { AdvanceCareADRTReducer } from "../../features/STGilesACP/reducers/ACPADRTReducers";

const appReducer = combineReducers({
	LoginReducers,
	SignupReducers,
	digitalLegacyReducers,
	ScheduledReducers,
	EventReducers,
	bucketReducers,
	noteReducers,
	playlistReducers,
	userReducers,
	alertReducers,
	loadingReducers,
	funeralReducers,
	advancePlanReducers,
	willReducers,
	accountReducers,
	feedbackReducers,
	STGilesACPReducers,
	giftReducers,
	ForgotPasswordReducers,
	ResetPasswordReducers,
	form: formReducer,

	// NEW VERSION
	LoginMywishesReducer,
	FeedbackReducer,
	RegisterStgilesReducer,
	ProductLastWillMywishesReducer,
	StripePaymentMywishesReducer,
	AdvanceCareADRTReducer
});

const rootReducer = (state, action) => {
	if (action.type == LoginConstants.UNAUTHENTICATED) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
