import alertConstants from "../constants/AlertConstants";

export default function(
	state = {
		showAlert: false,
		alertMessage: "",
		isWillPaymentShow: false
	},
	action
) {
	switch (action.type) {
		case alertConstants.SHOW_ALERT:
			return { ...state, showAlert: true, alertMessage: action.message };
		case alertConstants.CLOSE_ALERT:
			return { ...state, showAlert: false, alertMessage: "" };
		case alertConstants.SHOW_WILL_PAYMENT_MODAL:
			return { ...state, isWillPaymentShow: true };
		case alertConstants.HIDE_WILL_PAYMENT_MODAL:
			return { ...state, isWillPaymentShow: false };
		default:
			return state;
	}
}
