import styled from "styled-components";

const HelpText = styled.p`
	padding-left: 16px;
	padding-right: 16px;
	font-family: "Ubuntu", sans-serif;
	font-size: 1.2rem;
`;

export default HelpText;
