import Styled from "styled-components";

export const StyledRoot = Styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    @media (max-width: 768px) {
      height: 100%;
    }
`;
