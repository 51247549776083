const digitalLegacyConstants = {
	GET_LEGACY_REQUEST: "GET_LEGACY_REQUEST",
	GET_LEGACY_SUCCESS: "GET_LEGACY_SUCCESS",
	GET_LEGACY_FAILED: "GET_LEGACY_FAILED",
	ADD_LEGACY_REQUEST: "ADD_LEGACY_REQUEST",
	ADD_LEGACY_SUCCESS: "ADD_LEGACY_SUCCESS",
	ADD_LEGACY_FAILED: "ADD_LEGACY_FAILED",
	DELETE_LEGACY_REQUEST: "DELETE_LEGACY_REQUEST",
	DELETE_LEGACY_SUCCESS: "DELETE_LEGACY_SUCCESS",
	DELETE_LEGACY_FAILED: "DELETE_LEGACY_FAILED",
	UPDATE_LEGACY_REQUEST: "UPDATE_LEGACY_REQUEST",
	UPDATE_LEGACY_SUCCESS: "UPDATE_LEGACY_SUCCESS",
	UPDATE_LEGACY_FAILED: "UPDATE_LEGACY_FAILED",
	GOODBYE_ALREADY_EXISTS: "You have already created and saved a Goodbye Message and this message was assigned to your Trusted Contact. A list of your Trusted Contacts and whether or not they have 'confirmed the responsibility of being your Trusted Contact can be found in the 'My Profile' tab. If they have not seen the email request sent by us to them or ignored it the status in the 'My Profile' tab will display the text 'Pending'. <br/><br/> If they have 'Confirmed' responsibility the Trusted Contact will be able to administrate your Goodbye Message at a time after your death when they are ready to do so. The Goodbye Message will be sent out immediately on your public MyWishes page if and when your Trusted Contact administrates your account. Any scheduled Messages will then be sent out on the dates and times stated. Any messages that were due to be sent out before your account was administered are placed into a queue and sent out once a week until the backlog is clear and up to date. <br/><br/> MyWishes enables you to create and publish one Goodbye Message and up to 10 scheduled messages in the future. "
};

export default digitalLegacyConstants;
