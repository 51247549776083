import loadingConstants from "../constants/LoadingConstants";

export default function(
	state = {
        showLoading: false,
	},
	action
) {
	switch (action.type) {
		case loadingConstants.SHOW_LOADING:
            return { ...state, showLoading: true };
        case loadingConstants.CLOSE_LOADING:
            return { ...state, showLoading: false }
		default:
			return state;
	}
}
