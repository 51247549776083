import { Skeleton } from "antd";
import axios from "axios";
import { pick } from "lodash";
import { useInfiniteLoader, Masonry } from "masonic";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../shared/components/Layout";
import Loading from "../../shared/components/Loading";
import { Container } from "../../ui";
import cats from "./cat"
import { BlogBgTop, BlogBgWrapper, PostFeatureImgStyled, PostStyled } from "./styled";

const LearnHost = "https://learn.mywishes.co.uk";
const BlogHost = "https://blog.mywishes.co.uk";

const Card = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: transform 100ms ease-in-out;
	width: 100%;

	span:last-of-type {
	color: #fff;
	}

	&:hover {
	position: relative;
	transform: scale(1.125);
	z-index: 1000;
`

const CardImg = styled.img`
	width: 100%;
	display: block;
	display: block;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
`

const FakeCard = ({index, data: post, width, history}) => {
	return <PostStyled key={post.id} onClick={() => window.open(post.link, "_blank")}>
		<CardImg src={post.fimg_url}/>
		<div className="post-info">
			<div className="post-time">{moment(post.date).format("MMM Do, YYYY")}</div>
			<div className="post-title" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
		</div>
	</PostStyled>
};

const CommonBlogRss = (props) => {
	const [posts, setPosts] = useState([]);
	const [config, setConfig] = useState({page: 1, totalPages: 1, totalItems: 100})
	const [loading, setLoading] = useState(false)

	const fetchData = async(per_page) => {
		try {
			setLoading(true)
			const res = await axios.get(`${props.host}/wp-json/wp/v2/posts?per_page=${per_page}`);
			const posts = res.data.map(post => pick(post, ["id", "link", "date", "title", "fimg_url", "content", "_embedded"]));
			setPosts((prev) => [...prev, ...posts]);
			setConfig(prev => ({
				...prev,
				// page,
				totalPages: parseInt(res.headers["x-wp-totalpages"]),
				totalItems: parseInt(res.headers["x-wp-total"])
			}))
		} catch(e) {
			console.log(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchData(50)
	}, [])

	return (
		<Layout>
			<BlogBgWrapper>
				<BlogBgTop/>
				<div className="text">{props.title}</div>
			</BlogBgWrapper>
			<Container>
				{loading && <Loading />}
				<Masonry
					items={posts}
					columnGutter={24}
					columnWidth={172}
					overscanBy={1.25}
					maxColumnCount={3}
					render={(propsData) => <FakeCard {...propsData} {...props}/>}
				/>
			</Container>
		</Layout>
	)
}

export const LearnBlogRssRoute = (props) => <CommonBlogRss {...props} host={LearnHost} title="Tutorials"/>
export const BlogRssRoute = (props) => <CommonBlogRss {...props} host={BlogHost} title="Latest News" />

