const bucketConstants = {
	GET_BUCKET_REQUEST: "GET_BUCKET_REQUEST",
	GET_BUCKET_SUCCESS: "GET_BUCKET_SUCCESS",
	GET_BUCKET_FAILED: "GET_BUCKET_FAILED",
	ADD_BUCKET_REQUEST: "ADD_BUCKET_REQUEST",
	ADD_BUCKET_SUCCESS: "ADD_BUCKET_SUCCESS",
	ADD_BUCKET_FAILED: "ADD_BUCKET_FAILED",
	EDIT_BUCKET_REQUEST: "EDIT_BUCKET_REQUEST",
	EDIT_BUCKET_SUCCESS: "EDIT_BUCKET_SUCCESS",
	EDIT_BUCKET_FAILED: "EDIT_BUCKET_FAILED",
	DELETE_BUCKET_REQUEST: "DELETE_BUCKET_REQUEST",
	DELETE_BUCKET_SUCCESS: "DELETE_BUCKET_SUCCESS",
	DELETE_BUCKET_FAILED: "DELETE_BUCKET_FAILED",
}

export default bucketConstants;
