const AdvanceCareADRTConstants = {
	GET_ACP_ADRT_REQUEST: "GET_ACP_ADRT_REQUEST",
	GET_ACP_ADRT_SUCCESS: "GET_ACP_ADRT_SUCCESS",
	GET_ACP_ADRT_FAILED: "GET_ACP_ADRT_FAILED",

	SAVE_ACP_ADRT_SUCCESS: "SAVE_ACP_ADRT_SUCCESS",
	SAVE_ACP_ADRT_REQUEST: "SAVE_ACP_ADRT_REQUEST",
	SAVE_ACP_ADRT_FAILED: "SAVE_ACP_ADRT_FAILED",

	DOWNLOAD_ACP_ADRT_REQUEST: "DOWNLOAD_ACP_ADRT_REQUEST",
	DOWNLOAD_ACP_ADRT_SUCCESS: "DOWNLOAD_ACP_ADRT_SUCCESS",
	DOWNLOAD_ACP_ADRT_FAILED: "DOWNLOAD_ACP_ADRT_FAILED",

	SHARE_ACP_ADRT_REQUEST: "SHARE_ACP_ADRT_REQUEST",
	SHARE_ACP_ADRT_SUCCESS: "SHARE_ACP_ADRT_SUCCESS",
	SHARE_ACP_ADRT_FAILED: "SHARE_ACP_ADRT_FAILED"
};

export { AdvanceCareADRTConstants };
