import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

import allReducers from "../reducers";

// Setup
let middleWare = [promise(), thunk];

// Logger Middleware. This always has to be last
if (process.env.NODE_ENV !== "production") {
	middleWare = [...middleWare];
}

const store = createStore(allReducers, applyMiddleware(...middleWare));

export default store;
