const feedbackConstants = {
	GET_FEEDBACK_REQUEST: "GET_FEEDBACK_REQUEST",
	GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
	GET_FEEDBACK_FAILED: "GET_FEEDBACK_FAILED",
	ADD_FEEDBACK_REQUEST: "ADD_FEEDBACK_REQUEST",
	ADD_FEEDBACK_SUCCESS: "ADD_FEEDBACK_SUCCESS",
	ADD_FEEDBACK_FAILED: "ADD_FEEDBACK_FAILED",
}

export default feedbackConstants;
