import React from "react";
import isEmpty from "lodash/isEmpty";
import { ContainerType } from "./type";
import { StyledContainer, StyledContainerFluid } from "./styled";

// NEW VEERSION //
const Container = props => {
	const { children, container } = props;

	let component = (
		<StyledContainerFluid id="mw-container-fluid">
			{children}
		</StyledContainerFluid>
	);

	if (container === "mw-container") {
		component = (
			<StyledContainer id="mw-container">{children}</StyledContainer>
		);
	}

	return component;
};

Container.propTypes = ContainerType;

export default Container;
