import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Layout, Row } from "antd";
import _ from "lodash";

import SidebarNav from "./SidebarNav";
import HeaderNav from "./HeaderNav";
import ListSide from "./ListSide";
import FooterSide from "./FooterSide";

import * as actions from "../../../features/Profile/actions/UserActions";

import StaticAssetsConstants from "../../constants/StaticAssetsConstants";

const { Content } = Layout;

const ContentWrapper = styled(Content)`
	padding-left: 2em;
	padding-right: 2em;
	padding-top: 2em;
	overflow: none;
	min-height: 500px;
`;

class Container extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isNavCollapsed: false,
			isListSideCollapsed: false
		};

		this.toggleNav = this.toggleNav.bind(this);
		this.toggleSide = this.toggleSide.bind(this);
	}

	componentWillMount() {
		// Check if the user is accessing the app from mobile or not
		const isMobile = window.innerWidth <= 1024;
		this.setState({
			isNavCollapsed: isMobile,
			isListSideCollapsed: isMobile
		});
	}

	componentDidMount() {
		const { getUserInformation, history, username } = this.props;
		if (username === "") {
			getUserInformation(history);
		}
	}

	toggleNav() {
		this.setState({
			isNavCollapsed: !this.state.isNavCollapsed
		});
	}

	toggleSide() {
		this.setState({
			isListSideCollapsed: !this.state.isListSideCollapsed
		});
	}

	render() {
		const { isNavCollapsed, isListSideCollapsed } = this.state;
		const { listChildren, children } = this.props;

		const userProfile = _.get(this.props, "user_profile");
		const userAddress = _.get(userProfile, "user_addresses");
		const userPostalCode = _.chain(
			_.get(_.first(userAddress), "postal_code")
		).toUpper()
		.trim()
		.value();

		// TODO: IMPLEMENT RESPONSIVE LAYOUT
		return (
			<Layout
				style={{
					backgroundColor: "#fff",
					backgroundImage: `url(${StaticAssetsConstants.mywishesLayoutBG})`,
					backgroundSize: "initial"
				}}
			>
				<SidebarNav userPostalCode={userPostalCode} isNavCollapsed={isNavCollapsed} />
				<Layout style={{ background: "transparent" }}>
					<Layout style={{ background: "transparent", marginLeft: isNavCollapsed ? 80 : 250  }}>
						<HeaderNav
							listTrigger={!!listChildren}
							isNavCollapsed={isNavCollapsed}
							isListCollapsed={isListSideCollapsed}
							handleNavCollapse={this.toggleNav}
							handleSideCollapse={this.toggleSide}
						/>
						<ContentWrapper id="mywishes-root-overflow">
							<Row gutter={16} type="flex">
								{listChildren ? (
									<Fragment>
										<Col
											span={isListSideCollapsed ? 24 : 17}
											style={{
												minHeight: "80vh",
												paddingBottom: "10em"
											}}
										>
											{children}
										</Col>
										<Col span={isListSideCollapsed ? 0 : 7}>
											<ListSide
												isListCollapsed={
													isListSideCollapsed
												}
											>
												{listChildren}
											</ListSide>
										</Col>
									</Fragment>
								) : (
									<Col span={24}>{children}</Col>
								)}
							</Row>
						</ContentWrapper>
						<FooterSide />
					</Layout>
				</Layout>
			</Layout>
		);
	}
}

const mapStateToProps = ({ userReducers }) => {
	return userReducers;
};

export default withRouter(connect(mapStateToProps, actions)(Container));
