import axios from "axios";
import _ from "lodash";

import LoginConstants from "../constants/LoginConstants";
import AlertConstants from "../../../shared/constants/AlertConstants";
import {
	JWT_LOCAL_STORAGE,
	URL,
} from "../../../shared/constants/HostConstants";
import {
	requestErrorHandler,
	decodeUserId,
} from "../../../shared/utils/ActionUtils";
import {
	axiosInstance,
	axiosDocumentInstance,
} from "../../../apis/api-instance";

// get user information on routes
// if postcode null redirect to /my-profile
const _checkIsUserPostcodeFilled = async (props) => {
	const userProfile = _.get(props, "user_profile");
	const userAddress = _.get(userProfile, "user_addresses");
	const userPostalCode = _.chain(_.get(_.head(userAddress), "postal_code"))
		.toUpper()
		.trim()
		.value();

	if (!userPostalCode) {
		return window.location.replace("/my-profile");
	}
	return window.location.replace("/");
};

export function loginUser(credentials, history) {
	return async function (dispatch) {
		try {
			dispatch({
				type: LoginConstants.LOGIN_REQUEST,
			});
			const res = await axios.post(`${URL}/auth/login`, credentials);

			// redirect user to home page if the login process is success
			// if it isn't then dispatch login failure
			const { status, user_message, data } = res.data;
			if (status === "success") {
				const token = data;
				//set token to local storage
				localStorage.setItem(JWT_LOCAL_STORAGE, token);
				dispatch({
					type: LoginConstants.LOGIN_SUCCESS,
				});

				// rest request to get user profile
				const userId = decodeUserId(token);
				const res = await axios.post(
					`${URL}/user/profile`,
					{
						id: userId.id,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const userProfileProps = _.get(res.data, "data");
				await _checkIsUserPostcodeFilled(userProfileProps);

				// window.location.replace("/");
			} else {
				dispatch({
					type: LoginConstants.LOGIN_FAILURE,
				});

				if (user_message) {
					dispatch({
						type: AlertConstants.SHOW_ALERT,
						message: user_message,
					});
				}
			}
		} catch (err) {
			dispatch({
				type: LoginConstants.LOGIN_FAILURE,
			});
			requestErrorHandler(err, dispatch, history);
		}
	};
}

export function signOutUser() {
	return {
		type: LoginConstants.UNAUTHENTICATED,
	};
}
