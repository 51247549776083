import advancePlan from "../constants/ACPConstants";

export default function(
	state = {
		advancePlan: [],
		downloadLoading: false,
		shareLoading: false,
		loadings: {
			save: false,
			get: false
		},
		posturalDocuments: []
	},
	action
) {
	switch (action.type) {
		case advancePlan.GET_STGILES_ACP:
			return { ...state, advancePlan: action.payload };
		case advancePlan.ADD_STGILES_ACP_REQUEST:
			return {
				...state,
				loadings: {
					save: true,
					get: false
				}
			};
		case advancePlan.ADD_STGILES_ACP:
			return {
				...state,
				advancePlan: [action.payload, ...state.advancePlan],
				loadings: {
					save: false,
					get: false
				}
			};
		case advancePlan.DOWNLOAD_ACP_REQUEST:
			return { ...state, downloadLoading: true };
		case advancePlan.DOWNLOAD_ACP_SUCCESS:
			return { ...state, downloadLoading: false };
		case advancePlan.DOWNLOAD_ACP_FAILED:
			return { ...state, downloadLoading: false };
		case advancePlan.SHARE_ACP_REQUEST:
			return { ...state, shareLoading: true };
		case advancePlan.SHARE_ACP_SUCCESS:
			return { ...state, shareLoading: false };
		case advancePlan.SHARE_ACP_FAILED:
			return { ...state, shareLoading: false };
		case advancePlan.FETCH_POSTURAL_DOCUMENTS_SUCCESS:
			return {
				...state,
				posturalDocuments: action.payload
			};
		case advancePlan.FETCH_POSTURAL_DOCUMENTS_FAILED:
			return {
				...state,
				posturalDocuments: []
			};
		case advancePlan.REMOVE_POSTURAL_DOCUMENTS_REQUEST:
			return { ...state };
		case advancePlan.REMOVE_POSTURAL_DOCUMENTS_SUCCESS:
			return { ...state };
		default:
			return state;
	}
}
