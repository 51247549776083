import React from "react";
import isEmpty from "lodash/isEmpty";
import { GridType } from "./type";

// import "./index.scss";


// NEW VEERSION //

const Grid = props => {
	const { children } = props;

	return <div style={{display: 'flex'}}>{children}</div>;
};

Grid.propTypes = GridType;
export default Grid;
