import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { notification, Button, Modal } from "antd";
import Loadable from "react-loadable";
import Loading from "./Loading";
import { withRouter, Redirect } from "react-router-dom";
import _ from "lodash";

import { signOutUser } from "../features/Login/actions/LoginActions";
import { getUserInformation } from "../features/Profile/actions/UserActions";

import { SubTitle, Description } from "../shared/components/Text";

import {
	JWT_LOCAL_STORAGE,
	ACCEPT_COOKIES_LOCAL_STORAGE,
	HAS_EVER_LOGGED_FROM_MOBILE_LOCAL_STORAGE
} from "../shared/constants/HostConstants";
import { decodeUserId } from "../shared/utils/ActionUtils";

import LogoLarge from "../shared/components/logo/logoLarge";
import ScrollToTop from "./ScrollToTop";

const AsyncOnlineAccount = Loadable({
	loader: () => import("../features/OnlineAccount"),
	loading: Loading
});
const AsyncAdvanceCarePlan = Loadable({
	loader: () => import("../features/AdvanceCarePlan"),
	loading: Loading
});
const AsyncSTGilesACP = Loadable({
	loader: () => import("../features/STGilesACP"),
	loading: Loading
});
const AsyncACPMerger = Loadable({
	loader: () => import("../features/ACPMerger"),
	loading: Loading
});
const AsyncAdvanceDirectives = Loadable({
	loader: () => import("../features/AdvanceDirectives"),
	loading: Loading
});
const AsyncHomepage = Loadable({
	loader: () => import("../features/Homepage"),
	loading: Loading
});
const AsyncPrivateNote = Loadable({
	loader: () => import("../features/PrivateNote"),
	loading: Loading
});
const AsyncFeedback = Loadable({
	loader: () => import("../features/Feedback"),
	loading: Loading
});

const AsyncBucketList = Loadable({
	loader: () => import("../features/BucketList"),
	loading: Loading
});

const AsyncGoodbyeScheduled = Loadable({
	loader: () => import("../features/GoodbyeScheduled"),
	loading: Loading
});

const AsyncLastWillGift = Loadable({
	loader: () => import("../features/LastWillGift"),
	loading: Loading
});

const AsyncFuneralWishesPlaylist = Loadable({
	loader: () => import("../features/FuneralWishesPlaylist"),
	loading: Loading
});

const AsyncProfile = Loadable({
	loader: () => import("../features/Profile"),
	loading: Loading
});

const AsyncConfirmDeceased = Loadable({
	loader: () => import("../features/ConfirmDeceased"),
	loading: Loading
});

const AsyncForgotPassword = Loadable({
	loader: () => import("../features/ForgotPassword"),
	loading: Loading
});

const AsyncResetPassword = Loadable({
	loader: () => import("../features/ResetPassword"),
	loading: Loading
});

const AsyncNotFound = Loadable({
	loader: () => import("../features/NotFound/NotFound"),
	loading: Loading
});

const AsyncNotFoundContent = Loadable({
	loader: () => import("../features/NotFound/NotFoundContent"),
	loading: Loading
});

const AsyncLogin = Loadable({
	loader: () => import("../features/Login"),
	loading: Loading
});

const AsyncSignup = Loadable({
	loader: () => import("../features/Signup"),
	loading: Loading
});

const AsyncResendTokenSignup = Loadable({
	loader: () => import("../features/ResendTokenSignup"),
	loading: Loading
});

const AsyncFuneralHelp = Loadable({
	loader: () => import("../features/Help/Funeral"),
	loading: Loading
});

const AsyncWellbeing = Loadable({
	loader: () => import("../features/Wellbeing"),
	loading: Loading
});

const AsyncLegalHelp = Loadable({
	loader: () => import("../features/Help/Legal"),
	loading: Loading
});

const AsyncConversationsHelp = Loadable({
	loader: () => import("../features/Help/Conversations"),
	loading: Loading
});

const AsyncMindfulnessHelp = Loadable({
	loader: () => import("../features/Help/Mindfulness"),
	loading: Loading
});

const AsyncOnlineAccountHelp = Loadable({
	loader: () => import("../features/Help/OnlineAccounts"),
	loading: Loading
});

const AsyncViewWill = Loadable({
	loader: () => import("../features/DocumentView/ViewWill"),
	loading: Loading
});

import {LearnBlogRssRoute, BlogRssRoute} from "../features/LearnBlogRss/LearnBlogRss"
import AsyncLearnBlogRssDetail from "../features/LearnBlogRss/LearnBlogRssDetail"

// const AsyncLearnBlogRss = Loadable({
// 	loader: () => import("../features/LearnBlogRss/LearnBlogRss"),
// 	loading: Loading
// })

const AsyncViewFuneral = Loadable({
	loader: () => import("../features/DocumentView/ViewFuneral"),
	loading: Loading
});

const AsyncViewACP = Loadable({
	loader: () => import("../features/DocumentView/ViewACP"),
	loading: Loading
});

const AsyncInvitation = Loadable({
	loader: () => import("../features/Invitation"),
	loading: Loading
});

const AsyncLandingMobile = Loadable({
	loader: () => import("../features/LandingMobile"),
	loading: Loading
});

// *********************//
// New Version
// Will replace legacy as canary release
// *********************//

const AsyncLoginMywishes = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "NewLogin" */ "../app/features/login/modules/Mywishes"
		),
	modules: ["NewLogin"],
	loading: Loading
});

const AsyncRegisterStgiles = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "RegisterStgiles" */ "../app/features/register/modules/Stgiles"
		),
	modules: ["RegisterStgiles"],
	loading: Loading
});

const AsyncRegisterNewham = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "RegisterNewham" */ "../app/features/register/modules/NewhamWidget"
		),
	modules: ["RegisterNewham"],
	loading: Loading
});

function ProtectedRoute({ component: Component, ...routeProps }) {
	const token = localStorage.getItem(JWT_LOCAL_STORAGE);
	return (
		<Route
			{...routeProps}
			render={props =>
				token ? <Component {...props} /> : <Redirect to="/login" />
			}
		></Route>
	);
}

const ONE_MINUTE = 60;

// Max idle for tracking user's inactivity 30
const IDLE_TIMEOUT = ONE_MINUTE * 30;
// When the warning will show up 20
const IDLE_WARNING = ONE_MINUTE * 20;

const TABLET_WIDTH = 800;

// let warningModal;
let idleTime = 0;

class routerComponents extends Component {
	constructor(props) {
		super(props);

		this.state = {
			content_access: null,
			showModal: {
				visible: false,
				children: null,
				closable: false
			}
		};

		// this.checkAuth = this.checkAuth.bind(this);
		// this.checkIdleTime = this.checkIdleTime.bind(this);
		this.isMobile = this.isMobile.bind(this);
		this.checkFirstTimeMobile = this.checkFirstTimeMobile.bind(this);

		this.checkUserInactivity = this.checkUserInactivity.bind(this);
	}
	componentWillMount() {
		const token = localStorage.getItem(JWT_LOCAL_STORAGE);
		// check if token exists, if it isn't then force it to login first
		// exclude the public page
		if (token) {
			const user = decodeUserId(token);
			this.setState({ content_access: user.content_access });
		}
	}
	// checkIdleTime() {
	// 	const { history, signOutUser } = this.props;

	// 	const timeLeftMinute = Math.floor(
	// 		(IDLE_TIMEOUT - idleTime) / ONE_MINUTE
	// 	);
	// 	const timeLeftSecond = (IDLE_TIMEOUT - idleTime) % ONE_MINUTE;

	// 	const minuteToDisplay =
	// 		timeLeftMinute > 0
	// 			? `${timeLeftMinute} ${
	// 					timeLeftMinute < 2 ? "minute " : "minutes "
	// 			  }`
	// 			: null;

	// 	const content = (
	// 		<Fragment>
	// 			<Description smallmargin>
	// 				You have been inactive on MyWishes for the last 20 minutes.
	// 				You will be logged out of your MyWishes account in 10
	// 				minutes time. Please click on the page or save anything you
	// 				are working on. This will refresh the application you will
	// 				not be logged out until another 30 minutes of inactivity
	// 				takes place on the account.
	// 			</Description>
	// 			<Description center>
	// 				Time remaining: {minuteToDisplay}
	// 				{timeLeftSecond} {timeLeftSecond < 2 ? "second" : "seconds"}
	// 			</Description>
	// 		</Fragment>
	// 	);

	// 	if (idleTime >= IDLE_TIMEOUT) {
	// 		// gracefully stop checking idle time
	// 		clearInterval(this.idleIntervalChecking);
	// 		this.resetIdle();

	// 		// Force logout the current user
	// 		localStorage.removeItem(JWT_LOCAL_STORAGE);
	// 		signOutUser();
	// 		history.push("/login");
	// 		return;
	// 	}

	// 	if (IDLE_TIMEOUT > idleTime && idleTime >= IDLE_WARNING) {
	// 		// if warning still doesn't show up, set it to show up
	// 		if (warningModal == undefined) {
	// 			warningModal = Modal.warning({
	// 				title: <SubTitle>Inactivity Warning</SubTitle>,
	// 				content,
	// 				onOk: this.resetIdle
	// 			});
	// 		} else {
	// 			warningModal.update({
	// 				content
	// 			});
	// 		}
	// 	}

	// 	idleTime++;
	// }
	// resetIdle() {
	// 	idleTime = 0;
	// 	if (warningModal != undefined) {
	// 		warningModal.destroy();
	// 		warningModal = undefined;
	// 	}
	// }

	renderModal = () => {
		return (
			<Modal
				width={700}
				closable={this.state.showModal.closable}
				destroyOnClose
				centered={true}
				title={<LogoLarge type="black" />}
				visible={this.state.showModal.visible}
				// onCancel={e => {
				// 	idleTime = 0;
				// 	this.setState({
				// 		showModal: {
				// 			visble: false,
				// 			children: null,
				// 			footer: null
				// 		}
				// 	});
				// }}
				footer={this.state.showModal.footer}
			>
				{this.state.showModal.children}
			</Modal>
		);
	};

	renderModalDefaultFooter = (onCloseAction, onSubmitAction) => {
		return (
			<Fragment>
				{onCloseAction ? (
					<Button
						type="default"
						loading={this.state.showModal.isLoadingCloseButton}
						onClick={e => {
							this.setState(
								{
									showModal: {
										visible: false,
										children: null,
										footer: null
									}
								},
								() => {
									onCloseAction();
								}
							);
						}}
					>
						Close
					</Button>
				) : null}
				{onSubmitAction ? (
					<Button
						type="default"
						onClick={e => {
							this.setState(
								{
									showModal: {
										visible: false,
										children: null,
										footer: null
									}
								},
								() => {
									onSubmitAction();
								}
							);
						}}
					>
						Submit
					</Button>
				) : null}
			</Fragment>
		);
	};

	// New idle timeout logic 20191216
	checkUserInactivity() {
		const { history, signOutUser } = this.props;

		const timeLeftMinute = Math.floor(
			(IDLE_TIMEOUT - idleTime) / ONE_MINUTE
		);
		const timeLeftSecond = (IDLE_TIMEOUT - idleTime) % ONE_MINUTE;

		const minuteToDisplay =
			timeLeftMinute > 0
				? `${timeLeftMinute} ${
						timeLeftMinute < 2 ? "minute " : "minutes "
				  }`
				: null;

		window.onload = this.resetIdleTime;
		document.onmousemove = this.resetIdleTime;
		document.onkeypress = this.resetIdleTime;
		document.onscroll = this.resetIdleTime;
		document.onclick = this.resetIdleTime;
		document.onmousedown = this.resetIdleTime;

		if (idleTime >= IDLE_TIMEOUT) {
			// gracefully stop checking idle time
			clearInterval(this.checkUserIdleInterval);
			idleTime = 0;
			this.setState({
				showModal: {
					visble: false,
					children: null,
					footer: null,
					closable: false
				}
			});

			// Force logout the current user
			localStorage.removeItem(JWT_LOCAL_STORAGE);
			signOutUser();
			// history.push("/login");
			// window.location.replace("/login");
			return;
		}

		if (IDLE_TIMEOUT > idleTime && idleTime >= IDLE_WARNING) {
			// if warning still doesn't show up, set it to show up
			const onCloseAction = () => {
				// gracefully hide and reset idle time
				idleTime = 0;
				this.setState({
					showModal: {
						visble: false,
						children: null,
						footer: null,
						closable: false
					}
				});
			};
			const conttentInactivity = (
				<Fragment>
					<Description smallmargin>
						You have been inactive on MyWishes for the last 20
						minutes. You will be logged out of your MyWishes account
						in 10 minutes time. Please click on the page or save
						anything you are working on. This will refresh the
						application you will not be logged out until another 30
						minutes of inactivity takes place on the account.
					</Description>
					<Description center>
						Time remaining: {minuteToDisplay}
						{timeLeftSecond}{" "}
						{timeLeftSecond < 2 ? "second" : "seconds"}
					</Description>
				</Fragment>
			);

			const onSubmitAction = null;
			this.setState({
				showModal: {
					closable: false,
					visible: true,
					children: conttentInactivity,
					footer: this.renderModalDefaultFooter(
						onCloseAction,
						onSubmitAction
					)
				}
			});
		}

		idleTime++;
	}

	resetIdleTime() {
		idleTime = 0;
	}

	getCookieValue(targetCookie) {
		const cookieUsage = _.find(
			_.split(document.cookie, "; "),
			cookieName => {
				if (_.startsWith(cookieName, targetCookie)) {
					return _.split(cookieName, "=")[1];
				}
			}
		);
		return cookieUsage;
	}

	setCookieValue(name, value, maxAge) {
		const hostname = window.location.hostname;
		const domainName = hostname.replace(/^www\./, ".");
		const cookieString =
			name +
			"=" +
			value +
			"; domain=" +
			domainName +
			"; max-age=" +
			maxAge;
		document.cookie = `${cookieString}`;
	}

	checkCookiesPopup() {
		const mwCookieUsage = this.getCookieValue("_mw_cookie_usage");

		if (_.isEmpty(mwCookieUsage)) {
			notification.info({
				key: "COOKIES_AGREEMENT",
				duration: 0,
				message: <SubTitle>Cookies Agreement</SubTitle>,
				description: (
					<Fragment>
						<Description smallmargin>
							We use cookies on our website to help enhance your
							experience and provide you with relevant content.
						</Description>
						<Description center>
							To learn more{" "}
							<a href="//mywishes.co.uk/cookies">click here</a>
						</Description>
						<Description center>
							By using our websites and services you are agreeing
							to the use of Cookies
						</Description>
						<Button
							block
							type="primary"
							onClick={e => {
								this.setCookieValue(
									"_mw_cookie_usage",
									true,
									2592000
								);
								notification.close("COOKIES_AGREEMENT");
							}}
						>
							I Agree
						</Button>
					</Fragment>
				)
			});
		}
	}
	// checkAuth() {
	// 	const {
	// 		location: { pathname }
	// 	} = this.props;

	// 	const publicRoutes = [
	// 		"/login",
	// 		"/register",
	// 		"/mobile",
	// 		"/sendtoken",
	// 		"/forgot-password",
	// 		"/reset-password",
	// 		"/confirm-deceased",
	// 		"/southstaffs/register"
	// 	];
	// 	// get jwt token from local storage
	// 	const token = localStorage.getItem(JWT_LOCAL_STORAGE);
	// 	// check if token exists, if it isn't then force it to login first
	// 	// exclude the public page
	// 	if (!token && !publicRoutes.includes(pathname)) {
	// 		window.location.replace("/login");
	// 		return;
	// 	}

	// 	if (!publicRoutes.includes(pathname)) {
	// 		// start tracking inactivity
	// 		// this.idleIntervalChecking = setInterval(this.checkIdleTime, 1000);
	// 		// document.onclick = () => this.resetIdle();

	// 		// start track user inactivity 20191216
	// 		this.checkUserIdleInterval = setInterval(
	// 			this.checkUserInactivity,
	// 			1000
	// 		);

	// 		this.checkCookiesPopup();
	// 		this.checkFirstTimeMobile();
	// 	}
	// }
	isMobile() {
		if (window.innerWidth <= TABLET_WIDTH) {
			return true;
		} else {
			return false;
		}
	}
	checkFirstTimeMobile() {
		const { location } = window;
		if (location.pathname != "/") return;
		const LocalFirstTimeMobile = localStorage.getItem(
			HAS_EVER_LOGGED_FROM_MOBILE_LOCAL_STORAGE
		);
		if (LocalFirstTimeMobile == undefined && this.isMobile()) {
			localStorage.setItem(
				HAS_EVER_LOGGED_FROM_MOBILE_LOCAL_STORAGE,
				"true"
			);
			location.replace("/mobile");
		}
	}

	async componentDidMount() {
		// this.checkAuth();
	}

	render() {
		const { content_access } = this.state;
		const token = localStorage.getItem(JWT_LOCAL_STORAGE);
		return (
			<Fragment>
				{this.renderModal()}
				<ScrollToTop/>
				<Switch>
						<ProtectedRoute exact path="/" component={AsyncHomepage} />
						<ProtectedRoute
							exact
							path="/friends"
							component={AsyncInvitation}
						/>
						<ProtectedRoute
							exact
							path="/tutorials"
							component={LearnBlogRssRoute}
						/>

						<ProtectedRoute
							exact
							path="/my-wellbeing"
							component={AsyncWellbeing}
						/>
						
						<ProtectedRoute
							exact
							path="/latest-news"
							component={BlogRssRoute}
						/>
						
						<ProtectedRoute
							exact
							path="/tutorials/:id"
							component={AsyncLearnBlogRssDetail}
						/>
						
						<ProtectedRoute
							exact
							path="/funeral"
							component={AsyncFuneralHelp}
						/>
						<ProtectedRoute
							exact
							path="/legal"
							component={AsyncLegalHelp}
						/>
						<ProtectedRoute
							exact
							path="/conversations"
							component={AsyncConversationsHelp}
						/>
						<ProtectedRoute
							exact
							path="/mindfulness"
							component={AsyncMindfulnessHelp}
						/>
						<ProtectedRoute
							exact
							path="/online-accounts"
							component={AsyncOnlineAccountHelp}
						/>
						{/*<Route exact path="/donate" component={AsyncDonate} />*/}
						<ProtectedRoute
							exact
							path="/my-profile"
							component={AsyncProfile}
						/>
						<ProtectedRoute
							exact
							path="/my-private-notes"
							component={AsyncPrivateNote}
						/>

						<ProtectedRoute
							exact
							path="/my-online-accounts"
							component={AsyncOnlineAccount}
						/>
						<ProtectedRoute
							exact
							path="/my-goodbye-messages"
							component={AsyncGoodbyeScheduled}
						/>
						<ProtectedRoute
							exact
							path="/my-bucket-list"
							component={AsyncBucketList}
						/>
						<ProtectedRoute
							exact
							path="/feedback"
							component={AsyncFeedback}
						/>

						<Route
							exact
							path="/sendtoken"
							render={props =>
								!token ? (
									<AsyncResendTokenSignup {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							exact
							path="/login"
							render={props =>
								!token ? (
									<AsyncLogin {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							exact
							path="/register"
							render={props =>
								!token ? (
									<AsyncSignup {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							exact
							path="/confirm-deceased"
							component={AsyncConfirmDeceased}
						/>
						<Route
							exact
							path="/forgot-password"
							render={props =>
								!token ? (
									<AsyncForgotPassword {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							exact
							path="/reset-password"
							render={props =>
								!token ? (
									<AsyncResetPassword {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<ProtectedRoute
							exact
							path="/my-will-testament"
							component={
								content_access && content_access.last_will
									? AsyncLastWillGift
									: AsyncNotFoundContent
							}
						/>
						<ProtectedRoute
							exact
							path="/my-will-testament/:id"
							component={
								content_access && content_access.last_will
									? AsyncViewWill
									: AsyncNotFoundContent
							}
						/>
						<ProtectedRoute
							exact
							path="/my-funeral-wishes"
							component={AsyncFuneralWishesPlaylist}
						/>
						<ProtectedRoute
							exact
							path="/my-funeral-wishes/:id"
							component={AsyncViewFuneral}
						/>
						<ProtectedRoute
							exact
							path="/my-advance-care-plan"
							component={
								content_access && content_access.acp
									? AsyncACPMerger
									: AsyncNotFoundContent
							}
						/>

						<ProtectedRoute
							exact
							path="/my-advance-directives"
							component={
								content_access && content_access.ad
									? AsyncAdvanceDirectives
									: AsyncNotFoundContent
							}
						/>
						<ProtectedRoute
							exact
							path="/my-advance-care-plan/:id"
							component={
								content_access && content_access.acp
									? AsyncViewACP
									: AsyncNotFoundContent
							}
						/>
						<Route
							exact
							path="/mobile"
							component={AsyncLandingMobile}
						/>

						{/* New version */}
						<Route
							exact
							path="/beta/login"
							component={AsyncLoginMywishes}
						/>

						<Route
							exact
							path="/southstaffs/register"
							render={props =>
								!token ? (
									<AsyncRegisterStgiles {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>

						<Route
							exact
							path="/newham/register"
							render={props =>
								!token ? (
									<AsyncRegisterNewham {...props} />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route component={AsyncNotFound} />
					</Switch>
			</Fragment>
		);
	}
}

export default withRouter(
	connect(null, { signOutUser, getUserInformation })(routerComponents)
);
