import styled from "styled-components";
import { Link } from "react-router-dom";

const CustomLink = styled(Link)`
	color: #627a91;
	border-bottom: solid 1px black;
	padding-bottom: 4px;
`;

export default CustomLink;
