import React, { Component } from "react";
import MiniLogo from "../../../static/assets/img/My.png";

class LogoMini extends Component {
	render() {
		return (
			<span
				className="logo-mini"
				style={{
					display: "table",
					margin: "0 auto",
					position: "relative",
					top: "20%"
				}}
			>
				<img
					src={MiniLogo}
					alt="mywishes-mini-logo"
					style={{ width: "40px" }}
				/>
			</span>
		);
	}
}

export default LogoMini;
