const bucketConstants = {
	GET_NOTE_REQUEST: "GET_NOTE_REQUEST",
	GET_NOTE_SUCCESS: "GET_NOTE_SUCCESS",
	GET_NOTE_FAILED: "GET_NOTE_FAILED",
	ADD_NOTE_REQUEST: "ADD_NOTE_REQUEST",
	ADD_NOTE_SUCCESS: "ADD_NOTE_SUCCESS",
	ADD_NOTE_FAILED: "ADD_NOTE_FAILED",
	EDIT_NOTE_REQUEST: "EDIT_NOTE_REQUEST",
	EDIT_NOTE_FAILED: "EDIT_NOTE_FAILED",
	EDIT_NOTE_SUCCESS: "EDIT_NOTE_SUCCESS",
	DELETE_NOTE_REQUEST: "DELETE_NOTE_REQUEST",
	DELETE_NOTE_SUCCESS: "DELETE_NOTE_SUCCESS",
	DELETE_NOTE_FAILED: "DELETE_NOTE_FAILED",
}

export default bucketConstants;
