import { AdvanceCareADRTConstants } from "../constants/ADRTConstants";

const AdvanceCareADRTReducer = (
	state = {
		adrt: {
			data: [],
			intent: {
				isSave: false,
				isShare: false,
				isDownload: false,
				isLoading: false
			}
		}
	},
	action
) => {
	switch (action.type) {
		case AdvanceCareADRTConstants.GET_ACP_ADRT_REQUEST:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isLoading: true }
				}
			};
		case AdvanceCareADRTConstants.GET_ACP_ADRT_SUCCESS:
			return {
				...state,
				adrt: {
					data: action.payload,
					intent: { ...state.adrt.intent, isLoading: false }
				}
			};
		case AdvanceCareADRTConstants.GET_ACP_ADRT_FAILED:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isLoading: false }
				}
			};

		case AdvanceCareADRTConstants.SAVE_ACP_ADRT_REQUEST:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isSave: true }
				}
			};
		case AdvanceCareADRTConstants.SAVE_ACP_ADRT_FAILED:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isSave: false }
				}
			};
		case AdvanceCareADRTConstants.SAVE_ACP_ADRT_SUCCESS:
			return {
				...state,
				adrt: {
					data: action.payload,
					intent: { ...state.adrt.intent, isSave: false }
				}
			};

		case AdvanceCareADRTConstants.DOWNLOAD_ACP_ADRT_REQUEST:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isDonwload: true }
				}
			};
		case AdvanceCareADRTConstants.DOWNLOAD_ACP_ADRT_FAILED:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isDonwload: false }
				}
			};
		case AdvanceCareADRTConstants.DOWNLOAD_ACP_ADRT_SUCCESS:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isDonwload: false }
				}
			};

		case AdvanceCareADRTConstants.SHARE_ACP_ADRT_REQUEST:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isShare: true }
				}
			};
		case AdvanceCareADRTConstants.SHARE_ACP_ADRT_FAILED:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isShare: false }
				}
			};
		case AdvanceCareADRTConstants.SHARE_ACP_ADRT_SUCCESS:
			return {
				...state,
				adrt: {
					...state.adrt,
					intent: { ...state.adrt.intent, isShare: false }
				}
			};
		default:
			return state;
	}
};

export { AdvanceCareADRTReducer };
