import funeralConstants from "../constants/FuneralConstants";

export default function (
	state = {
		funeral: [],
		loadings: {
			add: false,
			get: false,
		}
	},
	action
) {
	switch (action.type) {
		case funeralConstants.GET_FUNERAL_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					get: true
				}
			}
		case funeralConstants.GET_FUNERAL_SUCCESS:
			return { ...state,
				funeral: action.payload,
				loadings: { ...state.loadings,
					get: false
				}
			};
		case funeralConstants.GET_FUNERAL_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					get: false
				}
			}
		case funeralConstants.ADD_FUNERAL_REQUEST:
			return { ...state,
				loadings: { ...state.loadings,
					add: true
				}
			}
		case funeralConstants.ADD_FUNERAL_SUCCESS:
			return {
				...state,
				funeral: [action.payload, ...state.funeral],
				loadings: { ...state.loadings,
					add: false
				}
			};
		case funeralConstants.ADD_FUNERAL_FAILED:
			return { ...state,
				loadings: { ...state.loadings,
					add: false
				}
			}
		default:
			return state;
	}
}
