// NEW VERSION
const FeedbackConstants = {
	FEEDBACK_SHOW_MODAL: "FEEDBACK_SHOW_MODAL",
	FEEDBACK_CLOSE_MODAL: "FEEDBACK_CLOSE_MODAL",
	FEEDBACK_SHOW_NOTIFICATION: "FEEDBACK_SHOW_NOTIFICATION",
	FEEDBACK_CLOSE_NOTIFICATION: "FEEDBACK_CLOSE_NOTIFICATION",
	FEEDBACK_SHOW_SKELETON: "FEEDBACK_SHOW_SKELETON",
	FEEDBACK_CLOSE_SKELETON: "FEEDBACK_CLOSE_SKELEETON",
	FEEDBACK_SHOW_LOADING: "FEEDBACK_SHOW_LOADING",
	FEEDBACK_CLOSE_LOADING: "FEEDBACK_CLOSE_LOADING"
};

export default FeedbackConstants;
