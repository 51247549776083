const userConstants = {
	GET_USER_INFO: "GET_USER_INFO",
	EDIT_USER_INFO: "EDIT_USER_INFO",
	EDIT_BANNER: "EDIT_BANNER",
	EDIT_AVATAR: "EDIT_AVATAR",
	DELETE_USER_ACCOUNT: "DELETE_USER_ACCOUNT",
	GET_USER_SOCIAL_PROFILE: "GET_USER_SOCIAL_PROFILE",
	EDIT_USER_SOCIAL_PROFILE: "EDIT_USER_SOCIAL_PROFILE",
	EDIT_USER_PRIVACY_PROFILE: "EDIT_USER_PRIVACY_PROFILE"
};

export default userConstants;
