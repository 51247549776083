import { ProductLastWillMywishesConstants } from "../constants";

const ProductLastWillMywishesReducer = (
	state = {
		isLoading: false,
		products: [],
	},
	action
) => {
	switch (action.type) {
		case ProductLastWillMywishesConstants.MW_PRODUCT_REQUEST:
			return { ...state, isLoading: true };
		case ProductLastWillMywishesConstants.MW_PRODUCT_SUCCESS:
			return { ...state, isLoading: false, products: action.products };
		case ProductLastWillMywishesConstants.MW_PRODUCT_FAILED:
			return { ...state, isLoading: false };
		default:
			return state;
			break;
	}
};

export { ProductLastWillMywishesReducer };
