import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import Store from "./shared/store";

import App from "./App";

// import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(
	<Provider store={Store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
/*
	we dont register service worker because it leads to caching problem with code splitting 
	e.g : when caching is enabled, there are some cases where the app loads the old chunks (code splitting)
	but that old chunks dont exist anymore bcs of app's updates
*/
// registerServiceWorker();
