import { LoginMywishesConstants } from "../constants";

const LoginMywishesReducer = (
	state = {
		isLoading: false
	},
	action
) => {
	switch (action.type) {
		case LoginMywishesConstants.MW_LOGIN_REQUEST:
			return { ...state, isLoading: true };
		case LoginMywishesConstants.MW_LOGIN_SUCCESS:
			return { ...state, isLoading: false };
		case LoginMywishesConstants.MW_LOGIN_FAILED:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};

export { LoginMywishesReducer };
