const giftConstants = {
	GET_GIFT_REQUEST: "GET_GIFT_REQUEST",
	GET_GIFT_SUCCESS: "GET_GIFT_SUCCESS",
	GET_GIFT_FAILED: "GET_GIFT_FAILED",
	ADD_GIFT_REQUEST: "ADD_GIFT_REQUEST",
	ADD_GIFT_SUCCESS: "ADD_GIFT_SUCCESS",
	ADD_GIFT_FAILED: "ADD_GIFT_FAILED",
	EDIT_GIFT_REQUEST: "EDIT_GIFT_REQUEST",
	EDIT_GIFT_SUCCESS: "EDIT_GIFT_SUCCESS",
	EDIT_GIFT_FAILED: "EDIT_GIFT_FAILED",
	DELETE_GIFT_REQUEST: "DELETE_GIFT_REQUEST",
	DELETE_GIFT_SUCCESS: "DELETE_GIFT_SUCCESS",
	DELETE_GIFT_FAILED: "DELETE_GIFT_FAILED",
}

export default giftConstants;
