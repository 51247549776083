import styled from "styled-components";

const SubDescription = styled.p `	
    color: #627a91;
    font-size: 16px;
    font-weight: 200;
    text-align: ${props => props.align};
`;

export default SubDescription;
