import React, { Component } from "react";
import LogoWhite from "../../../static/assets/img/mywishes-logo-white.png";
import LogoBlack from "../../../static/assets/img/mywishes-logo-black.png";
class LogoLarge extends Component {
	render() {
		return (
			<span
				className="logo-lg"
				style={ this.props.noStyle ? {} : {
					display: "table",
					margin: "0 auto",
					position: "relative",
					top: "20%"
				}}
			>
				<img
					src={this.props.type === "black" ? LogoBlack : LogoWhite}
					alt="mywishes-logo"
					style={
						this.props.style ? this.props.style : { width: "160px" }
					}
				/>
			</span>
		);
	}
}

export default LogoLarge;
